import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import tickImage from '../../assets/images/tick.svg'
import Label from "../Common/Label";


interface ApplicationProps {
    open: boolean;
    onClose: () => void;
}


const Application: React.FC<ApplicationProps> = ({ open, onClose }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 4,
        outline: 0,
        borderRadius:'8px'
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <img src={tickImage} alt="TickImage" />
                    <Label
                        children="Your Application Submitted"
                        fontWeight={700}
                        fontSize={'20px'}
                        color="#273848"
                    />
                </div>
                <button
                  type="submit"
                  className="user-scale mt-4 w-25 float-end"
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    color:'white',
                    width:'100%',
                    border:'none'
                  }}
                  onClick={onClose}
                >
                  Next
                </button>
            </Box>
        </Modal>
    )
}

export default Application;