import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import { putRequest } from "../../api/apiServices";
import { updateJob } from "../../api/apiList";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


interface JobProfileSecondProps {
  onBack: () => void;
  onNext: () => void; // Function to navigate to the next component
  setFormData: (data: any) => void;
  initialData: any;
  mode: any; //
}

const JobProfileSecond: React.FC<JobProfileSecondProps> = ({ onBack, onNext, setFormData, initialData, mode }: JobProfileSecondProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");

  const [taskOfThisPosition, setTaskOfThisPosition] = useState("");
  const [anyProjectsOfCandidates, setAnyProjectsOfCandidates] = useState("");
  const [educationAndExperience, setEducationAndExperience] = useState("");
  const [technicalSkillsRequired, setTechnicalSkillsRequired] = useState("");
  const [softSkillsRequired, setSoftSkillsRequired] = useState("");
  const [preferredCertificationsLicenses, setPreferredCertificationsLicenses] = useState("");
  const [idealCandidateProfile, setIdealCandidateProfile] = useState("");
  const [idealExperienceLevel, setIdealExperienceLevel] = useState("");
  const [industrySpecificExperienceRequired, setIndustrySpecificExperienceRequired] = useState("");
  const [loading,setLoading] = useState(false)

  //params to get id
  const params = useParams()
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/jobs')
  }

  const [errors, setErrors] = useState({
    taskOfThisPosition: "",
    anyProjectsOfCandidates: "",
    educationAndExperience: "",
    technicalSkillsRequired: "",
    softSkillsRequired: "",
    preferredCertificationsLicenses: "",
    idealCandidateProfile: "",
    idealExperienceLevel: "",
    industrySpecificExperienceRequired: "",
  });

  useEffect(() => {
    if (initialData) {
      setTaskOfThisPosition(initialData?.taskOfThisPosition)
      setAnyProjectsOfCandidates(initialData?.anyProjectsOfCandidates)
      setEducationAndExperience(initialData?.educationAndExperience)
      setTechnicalSkillsRequired(initialData?.technicalSkillsRequired)
      setSoftSkillsRequired(initialData?.softSkillsRequired)
      setPreferredCertificationsLicenses(initialData?.preferredCertificationsLicenses)
      setIdealCandidateProfile(initialData?.idealCandidateProfile)
      setIdealExperienceLevel(initialData?.idealExperienceLevel)
      setIndustrySpecificExperienceRequired(initialData?.industrySpecificExperienceRequired)
    }
  }, [])

  const handleBack = (event: any) => {
    event.preventDefault();
    // Handle form submission logic here
    onBack(); // Call the onNext function to navigate to the next component
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      taskOfThisPosition,
      anyProjectsOfCandidates,
      educationAndExperience,
      technicalSkillsRequired,
      softSkillsRequired,
      preferredCertificationsLicenses,
      idealCandidateProfile,
      idealExperienceLevel,
      industrySpecificExperienceRequired
    }
    if (formIsValid()) {
      if (mode) {
        setLoading(true)
        const response = await putRequest(updateJob(params?.id), data)
        setFormData(response?.data?.data)
        setLoading(false)
        onNext()
      }
      const allData = {
        ...initialData,
        ...data,
      };
      setFormData(allData)
      onNext(); // Call the onNext function to navigate to the next component
    }
  };

  const formIsValid = () => {
    const newErrors = {
      taskOfThisPosition: !taskOfThisPosition?.trim() ? "Primary Responsibilities is required" : "",
      anyProjectsOfCandidates: !anyProjectsOfCandidates?.trim() ? "Specific Projects or Initiativesr is required" : "",
      educationAndExperience: !educationAndExperience?.trim() ? "Qualifications is required" : "",
      technicalSkillsRequired: !technicalSkillsRequired?.trim() ? "Technical Skills is required" : "",
      softSkillsRequired: !softSkillsRequired?.trim() ? "Soft Skills is required" : "",
      preferredCertificationsLicenses: !preferredCertificationsLicenses?.trim() ? "Certifications is required" : "",
      idealCandidateProfile: !idealCandidateProfile?.trim() ? "Candidate Profile required" : "",
      idealExperienceLevel: !idealExperienceLevel?.trim() ? "Experience is required" : "",
      industrySpecificExperienceRequired: !industrySpecificExperienceRequired?.trim() ? "Industry-Specific Experience is required" : "",
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");

  };


  return (
    <div>
      <Container
        style={{
          display: "flex",
          padding: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <div className="col-12 pb-1">
              <ArrowBackIcon onClick={goBack} style={{cursor:'pointer'}} />
            </div>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item width="fit-content">
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Responsibilities & Qualifications
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid my={2}></Grid>
            {/* <Grid
              display="flex"
              alignItems="center"
              border="1px solid #c3c3c3"
              borderRadius="8px"
              padding="8px 0px 6px 15px"
            >
              <Grid direction="row" display="flex" gap={1} alignItems="center">
                <img src={CheckIcon} alt="overview-icon" />
                <Typography
                  //   className="input-label-custom"
                  fontWeight={700}
                  fontSize="13.94px"
                  lineHeight="13.94px"
                  color="#273848"
                >
                  Responsibilities & Qualifications
                </Typography>
              </Grid>
            </Grid> */}
            <Grid mt="22px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Key Responsibilities
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What are the primary responsibilities and tasks associated with
                this position? <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What are the primary responsibilities and tasks associated with this position?"
                fullWidth
                className="input-box-custom"
                error={!!errors.taskOfThisPosition}
                helperText={errors.taskOfThisPosition}
                value={taskOfThisPosition}
                onChange={(e) => setTaskOfThisPosition(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Are there any specific projects or initiatives the candidate
                will be involved in? <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Are there any specific projects or initiatives the candidate will be involved in?"
                fullWidth
                className="input-box-custom"
                error={!!errors.anyProjectsOfCandidates}
                helperText={errors.anyProjectsOfCandidates}
                value={anyProjectsOfCandidates}
                onChange={(e) => setAnyProjectsOfCandidates(e.target.value)}
              />
            </Grid>
            <Grid mt="18px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Qualifications and Skills
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What are the essential qualifications, both in terms of
                education and experience?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What are the essential qualifications, both in terms of education and experience?"
                fullWidth
                className="input-box-custom"
                error={!!errors.educationAndExperience}
                helperText={errors.educationAndExperience}
                value={educationAndExperience}
                onChange={(e) => setEducationAndExperience(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Are there any specific technical skills required for the role?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Are there any specific technical skills required for the role? "
                fullWidth
                className="input-box-custom"
                error={!!errors.technicalSkillsRequired}
                helperText={errors.technicalSkillsRequired}
                value={technicalSkillsRequired}
                onChange={(e) => setTechnicalSkillsRequired(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What soft skills are you looking for in a candidate?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What soft skills are you looking for in a candidate? "
                fullWidth
                className="input-box-custom"
                error={!!errors.softSkillsRequired}
                helperText={errors.softSkillsRequired}
                value={softSkillsRequired}
                onChange={(e) => setSoftSkillsRequired(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Are there preferred certifications or licenses?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Are there preferred certifications or licenses?"
                fullWidth
                className="input-box-custom"
                error={!!errors.preferredCertificationsLicenses}
                helperText={errors.preferredCertificationsLicenses}
                value={preferredCertificationsLicenses}
                onChange={(e) =>
                  setPreferredCertificationsLicenses(e.target.value)
                }
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Do you have an ideal candidate in mind when you think about this
                vacancy (what we can use as a reference)?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Do you have an ideal candidate in mind when you think about this vacancy (what we can use as a reference)? "
                fullWidth
                className="input-box-custom"
                error={!!errors.idealCandidateProfile}
                helperText={errors.idealCandidateProfile}
                value={idealCandidateProfile}
                onChange={(e) => setIdealCandidateProfile(e.target.value)}
              />
            </Grid>
            <Grid mt="18px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Experience Level
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What level of experience is ideal for this role (entry-level,
                mid-level, senior)? <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={idealExperienceLevel}
                fullWidth
                displayEmpty
                onChange={(e) => setIdealExperienceLevel(e.target.value)}
                renderValue={(value) => (
                  <span className={value === "" ? "placeholder" : ""}>
                    {value === "" ? "Select" : idealExperienceLevel}
                  </span>
                )}
              >
                <MenuItem value="" disabled>
                  {" "}
                  Select
                </MenuItem>
                <MenuItem value="No experience required">
                  No experience required
                </MenuItem>
                <MenuItem value="0 - 3 years of experience">
                  0 - 3 years of experience
                </MenuItem>
                <MenuItem value="3 - 5 years of experience">
                  3 - 5 years of experience
                </MenuItem>
                <MenuItem value="5 - 10 years of experience">
                  5 - 10 years of experience
                </MenuItem>
                <MenuItem value="More than 10 years of experience">
                  More than 10 years of experience
                </MenuItem>
              </Select>
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.idealExperienceLevel}
              </InputLabel>
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Is industry-specific experience necessary?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Is industry-specific experience necessary?"
                fullWidth
                className="input-box-custom"
                error={!!errors.industrySpecificExperienceRequired}
                helperText={errors.industrySpecificExperienceRequired}
                value={industrySpecificExperienceRequired}
                onChange={(e) =>
                  setIndustrySpecificExperienceRequired(e.target.value)
                }
              />
            </Grid>
            <Grid mt="40px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    // padding: "10px 50px",
                    // border:'none',
                    width:' 100%'
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </button>
              </Grid>
              <Grid item xs={mode ? 3 : 2} style={{ maxWidth: "fitContent" }}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    border:'none',
                    color:'white',
                    width:' 100%'
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? (
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save & Next"
                    )
                  ) : (
                    "Continue"
                  )}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JobProfileSecond;
