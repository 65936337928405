import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiList from "../../api/apiList";
import { postRequest } from "../../api/apiServices";
import FacebookIcon from "../../assets/images/Subscription/FaceBokkIcon.svg";
import GoogleIcon from "../../assets/images/Subscription/GoogleIcon.svg";
import InstagramIcon from "../../assets/images/Subscription/InstagramIcon.svg";
import LinkedInIcon from "../../assets/images/Subscription/LinkedInIcon.svg";
import XIcon from "../../assets/images/Subscription/XIcon.svg";
import { PlanType } from "../../helper/planConstant";
import PLAN_DETAILS from "../../helper/planDetails";
import "../../styles/inputBox.css";
import Toast from "../../utils/Toast";
import Loader from "../../utils/Loading";

const cardElementOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "#495057",
      "::placeholder": {
        color: "#6c757d",
      },
      padding: "12px 16px",
    },
    invalid: {
      color: "#e3342f",
      iconColor: "#e3342f",
    },
  },
};


const socialMediaIcons: any = {
  FACEBOOK: FacebookIcon,
  LINKEDIN: LinkedInIcon,
  INSTAGRAM: InstagramIcon,
  GOOGLE: GoogleIcon,
  TWITTER: XIcon,
};

const stripePromise = loadStripe(
  "pk_test_51PlnUlRtvsfObqn0hPtMd4FEMM1wW6ZZUuqFbBmDXPWdEX97m4bQncwxJslQM6Hq6KoeAw0PRZEF1NCpFsen1fFJ006uCdR6dN"
);

const PaymentGateway: React.FC = () => {
  // define stripe
  const stripe = useStripe();
  const params = useParams();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [plan, setPlan] = useState<any>();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:578px)");
  const location = useLocation();
  const navigate = useNavigate();

  const getSubscriptionPlan = async () => {
    setLoading(true);
    const response = await postRequest(apiList.subscriptionGet, {
      type: params.code,
    });
    setPlan(response?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getSubscriptionPlan();
  }, []);

  const handlePayment = async (event: any) => {
    event.preventDefault();
    setIsProcessing(true);

    // Call the API to initialize the payment
    const body = { plan: plan?.code };
    let data = await postRequest(apiList.initializePayment, body);

    // Confirm the card payment
    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          data?.data?.data?.clientSecret,
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        if (error) {
          navigate("/payment-cancel", { state: { planType: plan?.code } });
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
          const body = {
            paymentIntentId: paymentIntent.id,
            orderId: data?.data?.data?.orderId,
          };
          const response = await postRequest(apiList.confirmPayment, body);
          console.log("response: ", response);
          Toast("Payment successful!");
          navigate("/payment-success");
        } else {
          Toast("Payment processing...");
        }
      } else {
        Toast("Card element not found.");
      }
    } else {
      Toast("Stripe or Elements not loaded.");
    }

    setIsProcessing(false);
  };


  return loading ? (
    <Loader />
  ) : (
    <>
      <Container
        style={{
          height: isMobile ? "auto" : "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "50px",
          padding: "20px",
        }}
      >
        <Grid
          container
          spacing={2}
          bgcolor="#FFFFFF"
          width="100%"
          maxWidth="1200px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
          padding={isMobile ? "10px" : "20px"}
          direction={isMobile ? "column" : "row"}
        >
          {/* Plan Details Section */}
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            <Grid item width="fit-content">
              <Typography
                // color="#273848"
                fontWeight={600}
                style={{
                  backgroundColor:
                    "linear-gradient(90deg, #5DCCF0 0%, #8AEABF 100%)",
                }}
                fontSize="30px"
                className="payment-plan-title"
              >
                {plan?.name || ""}
              </Typography>
              <Typography color="#273848" fontWeight={400} fontSize="20.94px">
                {plan?.discount_price > 0 ? (
                  <>
                    <span
                      style={{
                        textDecoration: "line-through",
                        marginRight: "8px",
                      }}
                    >
                      € {plan?.discount_price}
                    </span>
                    <span>€ {plan?.price}</span>
                  </>
                ) : (
                  `€ ${plan?.price}`
                )}
              </Typography>
            </Grid>
            <Grid my={2}></Grid>  
            <Grid item width="230px">
              <Typography color="#273848" fontWeight={400} fontSize="16px">
                On average, about
                <Typography component="span" fontWeight={700} fontSize="16px">
                  {" "}
                  {plan?.description || ""}{" "}
                </Typography>
                apply for this package.
              </Typography>
            </Grid>
            <Grid my={1}></Grid>
            <Grid item width="fit-content" gap="10px" display="flex">
              {plan?.socialMedia.map((platform: string) => (
                <img
                  key={platform}
                  src={socialMediaIcons[platform]}
                  alt={`${platform} Icon`}
                />
              ))}
            </Grid>
            <Grid my={6}></Grid>
          </Grid>

          {/* Payment Method Section */}
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <Typography
              color="#273848"
              fontWeight={600}
              fontSize="20px"
              className="payment-plan-title"
            >
              Pay with card
            </Typography>
            <Grid my={1}></Grid>
            <Typography className="input-label-custom">Email</Typography>

            <TextField
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              fullWidth
              className="input-box-custom"
              sx={{ mt: 1 }}
              style={{ font: "caption" }}
              InputProps={{
                style: {
                  font: "caption",
                  fontWeight: 500,
                },
              }}
            />
            <Grid my={1}></Grid>

            <Typography className="input-label-custom">
              Card information
            </Typography>
            <Grid my={1}></Grid>
            <CardElement options={cardElementOptions} />
            <Grid my={1}></Grid>

            {/* <CardElement /> */}

            <Button
              variant="contained"
              type="submit"
              fullWidth
              className="free-plan-button"
              onClick={handlePayment}
              style={{
                backgroundColor: "#84E1CC",
                boxShadow: "none",
                borderRadius: "5px",
                padding: "17px 17px",
                lineHeight: "0px",
                textTransform: "none",
                letterSpacing: "0px",
                marginTop: "15px", // Add some margin to separate the button from the link
              }}
              disabled={!stripe || isProcessing}
            >
              <span className="free-plan-button-content">
                {" "}
                {isProcessing ? "Processing..." : "Pay Now"}
              </span>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const PaymentGatewayWithStripe: React.FC = () => (
  <Elements stripe={stripePromise}>
    <PaymentGateway />
  </Elements>
);

export default PaymentGatewayWithStripe;
