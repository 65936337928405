import React from "react";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { useNavigate } from "react-router-dom";

const Calendy: React.FC = () => {
  const navigate = useNavigate();

  const handleModalClose = () => {
    navigate('/'); // Redirect to the home page when the modal closes
  };

  const handleEventScheduled = () => {
    navigate('/interview-proccess'); // Redirect to the interview process page after the event is scheduled
  };

  // Use the hook to listen for the scheduled event
  useCalendlyEventListener({
    onEventScheduled: handleEventScheduled,
  });

  return (
    <div className="App">
      <PopupModal
        url="https://calendly.com/tusharcogtix/30min"
        rootElement={document.getElementById("root") as HTMLElement}
        pageSettings={{
          backgroundColor: 'transparent',
          hideEventTypeDetails: false,
          hideLandingPageDetails: false,
          primaryColor: '00a2ff',
          textColor: '4d5055',
        }}
        onModalClose={handleModalClose}
        open={true}
      />
    </div>
  );
};

export default Calendy;
