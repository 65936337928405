import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  IconButton,
  InputBase,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import plusIcon from "../../assets/images/plus.svg";
import EditIcon from '../../assets/images/EditIcon.svg';
import DeleteIcon from '../../assets/images/deleteIcon.svg';
import "../../styles/dashboard.css";
import Header from "../../layout/Header";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import { deleteRequest, postRequest } from "../../api/apiServices";
import { candidatesList, deleteCandidate } from "../../api/apiList";
import Loading from "../../utils/Loading";
import PaginationComponent from "../../utils/PaginationComponent";
import debounce from 'lodash/debounce';
import ShimmerEffect from "../../utils/ShimmerEffect";
import Toast from "../../utils/Toast";

const drawerWidth = 100;

interface jobDetails {
  id: number,
  jobTitle: string
}
interface Candidates {
  id: number;
  jobDetails: jobDetails;
  phone: string;
  email: string;
  name: string;
}

const CandidateListing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [candicate, setCandicate] = useState<Candidates[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [loader, setLoader] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  // Pagination
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0)
  }

  const handleUpdate = (id: number) => {
    navigate(`/candidates/update-candidate/${id}`);
  };

  const handleDeleteClick = (id: number) => {
    setSelectedCandidate(id);
    setOpenDialog(true);
  };

  const handleDelete = async () => {
    if (selectedCandidate !== null) {
      const response = await deleteRequest(deleteCandidate(selectedCandidate));
      Toast(response?.data?.statusMessage || "Candidate Delete Sucessfully...!");
      candidateList(page + 1, limit, searchTerm);
      setOpenDialog(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setSearchLoading(true);
    debouncedSearch(value);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter(event.target.value as string);
  };

  const handleClick = () => {
    navigate("/candidates/create-candidate");
  };

  //debounced search functionality
  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      candidateList(1, limit, searchValue);
      setSearchLoading(false);
    }, 500), []
  )

  const candidateList = async (page: number, limit: number, search: string) => {
    if (searchTerm) setLoader(true);
    const response = await postRequest(candidatesList, { page, limit, search, searchColumns: ["phone", "email","name"] });
    if (response?.data?.success) {
      setCandicate(response?.data?.data?.candidates);
      setCount(response?.data?.data?.count);
      setPage(response?.data?.data?.page - 1); // Convert 1-based index to 0-based index
      setHasNextPage(response?.data?.data?.hasNextPage);
      setHasPreviousPage(response?.data?.data?.hasPreviousPage);
    }
    setLoader(false);
  }

  useEffect(() => {
    if (!searchLoading) {
      candidateList(page + 1, limit, searchTerm);
    }
  }, [page, limit]);

  const inputStyle = {
    backgroundColor: '#ffffff',
    padding: '6px 12px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
    height: '40px',
  };

  return loader ? (
    <Loading />
  ) : (
    <>
      <Header  pageName="Candidates" isShow={true} />

      <Box
        sx={{
          ml: `${drawerWidth}px`,
          mt: "50px",
          backgroundColor: "#f0f0f0",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "flex-start", md: "space-between" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          mb={2}
        >
          <Container>
            <button
              type="submit"
              className="user-scale"
              // variant="contained"
              style={{
                backgroundColor: "#84E1CC",
                boxShadow: "none",
                borderRadius: "10px",
                fontSize: "16px",
                padding: "8px 27px",
                textTransform: "none",
                border: "1px solid #D6D6D7",
                height: "40px",
                color:'white',
                fontWeight:600
              }}
              onClick={handleClick}
            >
              Add New &nbsp;
              <img src={plusIcon} alt="Add new job" />
            </button>
          </Container>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            mt={{ xs: 2, md: 0 }}
          >
            <Box
              className="user-box"
              sx={{
                display: "flex",
                alignItems: "center",
                width: isMobile ? "auto" : "300px",
                ...inputStyle,
                marginRight: "16px",
              }}
            >
              <SearchIcon />
              <InputBase
                placeholder="Search here..."
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ ml: 1, flex: 1, fontSize: "16px" }}
              />
            </Box>
            <FormControl
              className="user-box"
              sx={{
                minWidth: 120,
                ...inputStyle,
                boxShadow: "none",
                margin: { xs: "0", sm: "16px 0" },
              }}
            >
              <InputLabel sx={{ color: "inherit",  marginTop:'-6px' }}>Filter</InputLabel>
              <Select
                value={filter}
                onChange={handleFilterChange}
                label="Filter"
                sx={{
                  height: "40px",
                  marginTop:'-4px',
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiSelect-select": {
                    padding: "8px 12px",
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="location">Location</MenuItem>
                <MenuItem value="hrname">Hiring Manager</MenuItem>
                <MenuItem value="created">Created Date</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Divider />
        <div>
        <Box mt={3} className="table-container">
          <Box className="table-wrapper" sx={{ backgroundColor: "#f0f0f0" }}>
            <Box display="flex" padding="16px 0" textAlign="center">
              <Box width="10%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Sr.
                </Typography>
              </Box>
              <Box width="20%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Position
                </Typography>
              </Box>
              <Box width="20%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Name
                </Typography>
              </Box>
              <Box width="20%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Contact Number
                </Typography>
              </Box>
              <Box width="20%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Email
                </Typography>
              </Box>
              <Box width="10%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Edit / Delete
                </Typography>
              </Box>
            </Box>
            {candicate && candicate.length > 0 ? (
              candicate.map((candidateIteam, index) => (
                <Box
                  className="row-shadow"
                  display="flex"
                  key={candidateIteam?.id}
                  alignItems="center"
                  textAlign="center"
                  bgcolor="#ffffff"
                  borderRadius="4px"
                  my={1}
                  py={1}
                >
                  {searchLoading ? (
                    <>
                      {Array.from({ length: 6 }, (_, index) => {
                        return (
                          <Box
                            key={index}
                            width={index === 0 || index === 5 ? "10%" : "20%"}
                            display="flex"
                            justifyContent="center"
                          >
                            <ShimmerEffect />
                          </Box>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <Box width="10%" display="flex" justifyContent="center">
                        <Typography
                          fontSize="16px"
                          fontWeight={500}
                          color="#273848"
                        >
                          {index + 1 + page * limit}
                        </Typography>
                      </Box>
                      <Box width="20%" display="flex" justifyContent="center">
                        <Typography
                          fontSize="16px"
                          fontWeight={500}
                          color="#273848"
                        >
                          {candidateIteam?.jobDetails?.jobTitle}
                        </Typography>
                      </Box>
                      <Box width="20%" display="flex" justifyContent="center">
                        <Typography
                          fontSize="16px"
                          fontWeight={500}
                          color="#273848"
                        >
                          {candidateIteam?.name}
                        </Typography>
                      </Box>
                      <Box width="20%" display="flex" justifyContent="center">
                        <Typography
                          fontSize="16px"
                          fontWeight={500}
                          color="#273848"
                        >
                          {candidateIteam?.phone}
                        </Typography>
                      </Box>
                      <Box width="20%" display="flex" justifyContent="center">
                        <Typography
                          fontSize="16px"
                          fontWeight={500}
                          color="#273848"
                        >
                          {candidateIteam?.email}
                        </Typography>
                      </Box>

                      <Box width="10%" display="flex" justifyContent="center">
                        <IconButton
                          onClick={() => handleUpdate(candidateIteam?.id)}
                        >
                          <img
                            src={EditIcon}
                            alt="Edit"
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "#273848",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteClick(candidateIteam?.id)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="Delete"
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </Box>
              ))
            ) : searchLoading ? (
              <ShimmerEffect />
            ) : (
              <Typography variant="body1" align="center">
                No results found.
              </Typography>
            )}
          </Box>
        </Box>
        <PaginationComponent
          page={page}
          rowsPerPage={limit}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={count}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
        />
        </div>
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Please Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this candidate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="warning">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CandidateListing;
