import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  MenuItem,
  InputLabel,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";
import { multiPartPUTRequest, putRequest } from "../../api/apiServices";
import { updateCompany } from "../../api/apiList";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface ProfileSecondProps {
  onBack: () => void; // Function to navigate back to the previous component
  onNext: () => void; // Function to navigate to the next component
  setProfileData: (data: any) => void;
  initialData: any;
  mode: any
}


const ProfileSecond: React.FC<ProfileSecondProps> = ({
  onBack,
  onNext,
  setProfileData, initialData, mode
}: ProfileSecondProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");

  const [companyVisionAndMission, setCompanyVisionAndMission] = useState("");
  const [employees, setEmployees] = useState("");
  const [industry, setIndustry] = useState("");
  const [services, setServices] = useState("");
  const [loading , setLoading] = useState(false)

  const [errors, setErrors] = useState({
    companyVisionAndMission: "",
    employees: "",
    industry: "",
    services: "",
  });

  const params = useParams()
  


  const handleBack = () => {
    onBack();
  };
  const formIsValid = () => {
    const newErrors = {
      companyVisionAndMission: !companyVisionAndMission?.trim() ? "Company Mission And Vission is required" : "",
      employees: !employees?.trim() ? "Employee is required" : "",
      industry: !industry?.trim() ? "Industry is required" : "",
      services: !services?.trim() ? "Service is required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  useEffect(() => {
    if (initialData) {
      setCompanyVisionAndMission(initialData?.companyVisionAndMission)
      setEmployees(initialData?.employees)
      setIndustry(initialData?.industry)
      setServices(initialData?.services)
    }
  }, [])

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      companyVisionAndMission,
      employees,
      industry,
      services
    }
    if (mode) {
      setLoading(true)
      const response = await multiPartPUTRequest(updateCompany(params?.id), data)
      setProfileData(response?.data?.data)
      setLoading(false)
      onNext()
    } else {
      if (formIsValid()) {
        const allData = {
          ...initialData,
          ...data,
        };
        setProfileData(allData)
        onNext(); // Call the onNext function to navigate to the next component
      }
    }
  };

  return (
    <div>
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          padding: "20px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="784px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <div className="col-12 mb-1">
              <ArrowBackIcon onClick={handleBack} style={{ cursor: 'pointer' }} />
            </div>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item xs={8}>
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Create Profile
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Company Mission and Vision
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={companyVisionAndMission}
                onChange={(e) => setCompanyVisionAndMission(e.target.value)}
              />
            </Grid>
            <InputLabel
              style={{
                color: "#f44336",
                paddingTop: "3px",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
            >
              {errors.companyVisionAndMission}
            </InputLabel>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">Employees</Typography>
              <Grid mb={1}></Grid>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={employees}
                fullWidth
                onChange={(e) => setEmployees(e.target.value)}
                displayEmpty
                renderValue={(value) => (
                  <span className={value === "" ? "placeholder" : ""}>
                    {value === "" ? "Select number of employees" : value}
                  </span>
                )}
              >
                <MenuItem value="" disabled>
                  Select number of employees
                </MenuItem>
                <MenuItem value={"0-10"}>0-10</MenuItem>
                <MenuItem value={"10-50"}>10-50</MenuItem>
                <MenuItem value={"50-200"}>50-200</MenuItem>
                <MenuItem value={"200-1000"}>200-1000</MenuItem>
              </Select>
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.employees}
              </InputLabel>
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">Industry</Typography>
              <Grid mb={1}></Grid>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={industry}
                fullWidth
                onChange={(e) => setIndustry(e.target.value as string)}
                displayEmpty
                renderValue={(value) => (
                  <span className={value === "" ? "placeholder" : ""}>
                    {value === "" ? "Select industry" : value}
                  </span>
                )}
              >
                <MenuItem value="" disabled>
                  Select industry
                </MenuItem>
                <MenuItem value={"Industry 1"}>Industry 1</MenuItem>
                <MenuItem value={"Industry 2"}>Industry 2</MenuItem>
                <MenuItem value={"Industry 3"}>Industry 3</MenuItem>
                <MenuItem value={"Industry 4"}>Industry 4</MenuItem>
              </Select>
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.industry}
              </InputLabel>
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">Services</Typography>
              <Grid mb={1}></Grid>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={services}
                fullWidth
                onChange={(e) => setServices(e.target.value as string)}
                displayEmpty
                renderValue={(value) => (
                  <span className={value === "" ? "placeholder" : ""}>
                    {value === "" ? "Select services" : value}
                  </span>
                )}
              >
                <MenuItem value="" disabled>
                  Select services
                </MenuItem>
                <MenuItem value={"Services 1"}>Services 1</MenuItem>
                <MenuItem value={"Services 2"}>Services 2</MenuItem>
                <MenuItem value={"Services 3"}>Services 3</MenuItem>
                <MenuItem value={"Services 4"}>Services 4</MenuItem>
              </Select>
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.services}
              </InputLabel>
            </Grid>
            <Grid mt="155px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2.5}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                  }}
                  onClick={handleBack}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={3} style={{ maxWidth: "fitContent" }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? (
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save & Next"
                    )
                  ) : (
                    "Continue"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ProfileSecond;
