// Loader.tsx
import React from 'react';
import './Loading.css'; // Import loader styles

const Loader = () => {
  return (
    <div className="shimmer-loader-wrapper">
      <div className="shimmer-loader">
        <div className="shimmer"></div>
        <div className="spinner"></div>
      </div>
    </div>
  );
};

export default Loader;
