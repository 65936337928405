import React from "react";
import Label from "../Common/Label";
import LightBulg from '../../assets/images/Interview/lightBulb.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface InterviewTipProps {
  handleNext: () => void;
  handleBack: () => void;
}

const InterviewTip: React.FC<InterviewTipProps> = ({ handleNext, handleBack }) => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid #D6D6D7',
          borderRadius: '27px',
          padding: '45px 42px 40px 42px',
          width: '650px'

        }}
      >
        <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={handleBack} />
        <div className="row d-flex align-items-center justify-content-center flex-column" style={{ gap: '33px' }}>
          <div className="d-flex align-items-center justify-content-center flex-column" style={{ gap: '24px' }}>
            <img src={LightBulg} className="img-fluid" alt="LightBulg" />
            <Label
              children="Interview Tips"
              fontSize="20.94px"
              fontWeight={700}
              color="#273848"
            />
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column" style={{ gap: '30px' }}>
            <textarea className="form-control" placeholder="Type here..." id="floatingTextarea2" style={{ height: "177px" }}></textarea>
            <button
              type="submit"
              className="user-scale"
              // variant="contained"
              // fullWidth
              style={{
                backgroundColor: "#84E1CC",
                boxShadow: "none",
                borderRadius: "5px",
                padding: "20px 17px",
                lineHeight: "0px",
                letterSpacing: "0px",
                textTransform: "none",
                color: 'white',
                border: 'none'
              }}
              onClick={handleNext}
            >
              Call To Action
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewTip;

{/* <button onClick={handleBack}>Back</button>
      <button onClick={handleNext}>Next</button> */}
