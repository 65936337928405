import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  TextField,
  InputLabel,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import { useNavigate, useParams } from "react-router-dom";
import { putRequest } from "../../api/apiServices";
import { updateJob } from "../../api/apiList";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface JobProfilefourthProps {
  onBack: () => void;
  onNext: () => void; // Function to navigate to the next component
  setFormData: (data: any) => void;
  initialData: any;
  mode: any;
}

const JobProfilefourth: React.FC<JobProfilefourthProps> = ({ onBack, onNext, setFormData, initialData, mode }: JobProfilefourthProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");

  // filed decalre
  const [teamInteraction, setTeamInteraction] = useState("")
  const [collaborationRequirements, setCollaborationRequirements] = useState("")
  const [reportingStructure, setReportingStructure] = useState("")
  const [cultureFit, setCultureFit] = useState("")
  const [loading,setLoading] = useState(false)

  //params to get id
  const params = useParams()
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/jobs')
  }

  const [errors, setErrors] = useState({
    teamInteraction: "",
    collaborationRequirements: "",
    reportingStructure: "",
    cultureFit: ""
  })

  useEffect(() => {
    if (initialData) {
      setTeamInteraction(initialData?.teamInteraction)
      setCollaborationRequirements(initialData?.collaborationRequirements)
      setReportingStructure(initialData?.reportingStructure)
      setCultureFit(initialData?.cultureFit)
    }
  }, [])


  const handleBack = (event: any) => {
    event.preventDefault();
    onBack();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      teamInteraction,
      collaborationRequirements,
      reportingStructure,
      cultureFit
    }
    if (formIsValid()) {
      if (mode) {
        setLoading(true)
        const response = await putRequest(updateJob(params?.id), data)
        setFormData(response?.data?.data)
        setLoading(false)
        onNext()
      } else {
        const allData = {
          ...initialData,
          ...data,
        }
        setFormData(allData)
        onNext();
      }
    }
  };

  const formIsValid = () => {
    const newErrors = {
      teamInteraction: !teamInteraction?.trim() ? "Team and Department Collaboration is required" : "",
      collaborationRequirements: !collaborationRequirements?.trim() ? "Collaboration Requirements is required" : "",
      reportingStructure: !reportingStructure?.trim() ? "Reporting Structure is required" : "",
      cultureFit: !cultureFit?.trim() ? "Team Culture Fit is required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  return (
    <div>
      <Container
        style={{
          display: "flex",
          padding: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <div className="col-12 pb-1">
              <ArrowBackIcon onClick={goBack} style={{ cursor: 'pointer' }} />
            </div>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item width="fit-content">
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Team & Culture
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt="26px"></Grid>

            {/* <Grid
              display="flex"
              alignItems="center"
              border="1px solid #c3c3c3"
              borderRadius="8px"
              padding="8px 0px 6px 15px"
            >
              <Grid direction="row" display="flex" gap={1} alignItems="center">
                <img src={TeamIcon} alt="team-icon" />
                <Typography
                  //   className="input-label-custom"
                  fontWeight={700}
                  fontSize="13.94px"
                  lineHeight="13.94px"
                  color="#273848"
                >
                  Team & Culture
                </Typography>
              </Grid>
            </Grid> */}

            <Grid mt="22px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Team and Department Collaboration
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                How does this role interact with other teams or departments
                within the organization? <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={teamInteraction}
                onChange={(e) => setTeamInteraction(e.target.value)}
              />
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.teamInteraction}
              </InputLabel>
            </Grid>
            <Grid mt="28px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Are there specific collaboration requirements?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Are there specific collaboration requirements?"
                fullWidth
                className="input-box-custom"
                error={!!errors.collaborationRequirements}
                helperText={errors.collaborationRequirements}
                value={collaborationRequirements}
                onChange={(e) => setCollaborationRequirements(e.target.value)}
              />
            </Grid>
            <Grid mt="25px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Reporting Structure
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                To whom will the candidate report, and who will be their direct
                team members? <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={reportingStructure}
                onChange={(e) => setReportingStructure(e.target.value)}
              />
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.reportingStructure}
              </InputLabel>
            </Grid>
            <Grid mt="24px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
              //   className="input-label-custom"
            >
              Team Culture Fit
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What is the team culture like, and what attributes should the
                candidate possess to fit in well?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What is the team culture like, and what attributes should the candidate possess to fit in well?"
                fullWidth
                className="input-box-custom"
                error={!!errors.cultureFit}
                helperText={errors.cultureFit}
                value={cultureFit}
                onChange={(e) => setCultureFit(e.target.value)}
              />
            </Grid>
            <Grid mt="40px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    // padding: "10px 50px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                   width:' 100%'
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </button>
              </Grid>
              <Grid item xs={mode ? 3 : 2} style={{ maxWidth: "fitContent" }}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    border:'none',
                    color:'white',
                    width:' 100%'
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? (
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save & Next"
                    )
                  ) : (
                    "Continue"
                  )}
                </button>
              </Grid>
            </Grid>
            <Grid mb="20px"></Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JobProfilefourth;
