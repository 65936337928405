import React from "react";
import Label from "../Common/Label";

interface FeedbackProps {
  handleNext: () => void;
  handleBack: () => void;
}

const Feedback: React.FC<FeedbackProps> = ({ handleNext, handleBack }) => {
  return (
    <div
      style={{
        paddingTop: '32px',
      }}
    >
      <div className="container" style={{paddingBottom:'34px'}}>
        <div className="row g-4">
          <div className="col-md-4">
            <div style={{
              backgroundColor: 'white',
              border: '1px solid #D6D6D7',
              borderRadius: '27px',
              padding: '27px',
              height: 'auto',
            }}>
              <div className="d-flex align-items-center justify-content-start" style={{ gap: '27px' }}>
                <img src="https://randomuser.me/api/portraits/women/66.jpg" className="img-fluid" style={{ height: '86px', width: '86px', borderRadius: '50%', objectFit: 'cover' }} alt="USERIMAGE" />
                <div>
                  <Label
                    children="Your Name"
                    fontWeight={700}
                    fontSize={'20.48px'}
                    color={"#273848"}
                  />
                  <Label
                    children="Job Title"
                    fontWeight={500}
                    fontSize={'16.85px'}
                    color={"#273848"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div style={{
              backgroundColor: 'white',
              border: '1px solid #D6D6D7',
              borderRadius: '27px',
              padding: '27px 27px 27px 27px',
              height: '100%',
            }}>
              <div className="row">
                <div className="col-12">
                  <Label
                    children='Give a Feedback'
                    fontWeight={700}
                    fontSize='20.94px'
                    color='#273848'
                  />
                  <Label
                    children='Lorem ipsum dolor sit amet consectetur. Tortor odio.'
                    fontWeight={500}
                    fontSize='12.94px'
                    color='#273848'
                  />
                </div>

                <div className="row d-flex align-items-start justify-content-center flex-column" style={{ marginTop: '23px', gap: '26px' }}>

                  <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                    <Label
                      children='Short summary'
                      fontWeight={500}
                      fontSize='14px'
                      color='#4e4e4e'
                    />
                    <textarea className="form-control" placeholder="Type here..." id="floatingTextarea" style={{ height: '142px', border: '1px solid #d9d9d9', boxShadow: 'none' }}></textarea>
                  </div>

                  <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                    <Label
                      children='Personal / team fit'
                      fontWeight={500}
                      fontSize='14px'
                      color='#4e4e4e'
                    />
                    <input type="text" className="form-control" placeholder="Personal / team fit" id="floatingTextarea" style={{ border: '1px solid #d9d9d9', boxShadow: 'none' }} />
                  </div>

                  <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                    <Label
                      children='Skills'
                      fontWeight={500}
                      fontSize='14px'
                      color='#4e4e4e'
                    />
                    <input type="text" className="form-control" placeholder="Skills" id="floatingTextarea" style={{ border: '1px solid #d9d9d9', boxShadow: 'none' }} />
                  </div>

                  <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                    <Label
                      children='Positives'
                      fontWeight={500}
                      fontSize='14px'
                      color='#4e4e4e'
                    />
                    <input type="text" className="form-control" placeholder="Positives" id="floatingTextarea" style={{ border: '1px solid #d9d9d9', boxShadow: 'none' }} />
                  </div>

                  <div className="d-flex align-items-start justify-content-center flex-column gap-1">
                    <Label
                      children='Areas for improvement'
                      fontWeight={500}
                      fontSize='14px'
                      color='#4e4e4e'
                    />
                    <textarea className="form-control" placeholder="Type here..." id="floatingTextarea" style={{ height: '142px', border: '1px solid #d9d9d9', boxShadow: 'none' }}></textarea>
                  </div>

                  <div className="d-flex align-items-end justify-content-end">
                    <button style={{
                      borderRadius:'5px',
                      backgroundColor:'#84e1cc',
                      border:'none',
                      padding:'7px 30px',
                      color:'white',
                      fontWeight:500,
                      fontSize:'17.94px'
                    }}>Call To Action</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Feedback;
