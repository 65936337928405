import React from 'react';
import Calendy from '../../components/Calendy';

const Interviews : React.FC = () => {
    return(
        <>
            <Calendy />
        </>
    )
}

export default Interviews;