import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  TextField,
  InputLabel,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import { useNavigate, useParams } from "react-router-dom";
import { postRequest, putRequest } from "../../api/apiServices";
import { createCandidate, updateCandidate, updateJob } from "../../api/apiList";
import Toast from "../../utils/Toast";

interface CandidateSpeFifthProps {
  onNext: () => void; // Function to navigate to the next component
  onBack: () => void;
  setCandidateData: (data: any) => void;
  initialData: any;
  mode: any;
}

const CandidateSpeFifth: React.FC<CandidateSpeFifthProps> = ({ onNext, onBack, setCandidateData, initialData, mode }: CandidateSpeFifthProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");
  const navigate = useNavigate();

  // Handle From Filed
  const [jobFactores, setJobFactores] = useState("")
  const [preferenceCultureGrowthCompensation, setPreferenceCultureGrowthCompensation] = useState("")
  const [interviewedOtherCompanies, setInterviewedOtherCompanies] = useState("")
  const [loading,setLoading] = useState(false)


  //params 
  const params = useParams()


  const [errors, setErrors] = useState({
    jobFactores: "",
    preferenceCultureGrowthCompensation: "",
    interviewedOtherCompanies: "",
  });

  const formIsValid = () => {
    const newErrors = {
      jobFactores: !jobFactores?.trim() ? "Factors Are Crucial is required" : "",
      preferenceCultureGrowthCompensation: !preferenceCultureGrowthCompensation?.trim() ? "Company Culture, Career Growth, And Compensation is required" : "",
      interviewedOtherCompanies: !interviewedOtherCompanies?.trim() ? "Interviewed With Other Companies is required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  useEffect(() => {
    if (initialData) {
      setJobFactores(initialData?.jobFactores)
      setPreferenceCultureGrowthCompensation(initialData?.preferenceCultureGrowthCompensation)
      setInterviewedOtherCompanies(initialData?.interviewedOtherCompanies)
    }
  }, [])

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const newData = {
      jobFactores,
      preferenceCultureGrowthCompensation,
      interviewedOtherCompanies,
    }
    if (formIsValid()) {
      if (mode) {
        setLoading(true)
        const response = await putRequest(updateCandidate(params?.id), newData)
        if (response) {
          setLoading(false)
          Toast(response?.data?.statusMessage || "Candidate Updated Successfully")
          navigate("/candidates");
        }
        setLoading(false)
      } else {
        const allData = {
          ...initialData,
          ...newData,
        };
        setCandidateData(allData)
        const response = await postRequest(createCandidate, allData)
        if (response) {
          navigate("/candidates");
          Toast(response?.data?.statusMessage || "Candidate Create Successfully")
        }
      }
    }
  };
  const handleBack = (event: any) => {
    event.preventDefault();
    // Handle form submission logic here
    onBack(); // Call the onNext function to navigate to the next component
  };

  return (
    <div >
      <Container
        style={{
          display: "flex",
          padding:'20px',
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item width="fit-content">
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Ideal Situation & Other Interviews
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt="24px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            >
              Decisive Factors in Job Selection
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What factors are crucial for you when choosing a new job?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={jobFactores}
                onChange={(e) => setJobFactores(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.jobFactores}</InputLabel>
            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                How do you prioritize between company culture, career growth,
                and compensation?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                placeholder="How do you prioritize between company culture, career growth, and compensation?"
                fullWidth
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                className="input-box-custom"
                error={!!errors.preferenceCultureGrowthCompensation}
                helperText={errors.preferenceCultureGrowthCompensation}
                value={preferenceCultureGrowthCompensation}
                onChange={(e) => setPreferenceCultureGrowthCompensation(e.target.value)}
              />
            </Grid>
            <Grid mt="34px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            >
              Other Interview Processes
            </Typography>
            <Grid mt="18px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Have you interviewed with other companies recently? If so, how
                does this opportunity compare?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                style={{ display: "inline-block", padding: "25px 11px" }}
                className="text-area-size"
                value={interviewedOtherCompanies}
                onChange={(e) => setInterviewedOtherCompanies(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.interviewedOtherCompanies}</InputLabel>
            <Grid mt="190px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2}>
                <button
                  type="submit"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                    width:'100%'
                    // padding: "10px 50px",
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </button>
              </Grid>
              <Grid item xs={mode ? 3 : 2} style={{ maxWidth: "fitContent" }}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    color:'white',
                    width:'100%',
                    border:'none'
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? loading ?<CircularProgress size={20}  color="inherit"/>   : "Save & Next" : "Continue"}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CandidateSpeFifth;
