import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Container,
    useMediaQuery,
    Box,
    Button,
    TextField,
    Link,
    InputLabel,
    CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import uploadFileIcon from "../../assets/images/uploadFileIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, multiPartPUTRequest } from "../../api/apiServices";
import { getUserProfile, updateUserProfile } from "../../api/apiList";
import Toast from "../../utils/Toast";
import { updateUserData } from "../../services/authService";
import Loading from "../../utils/Loading"

interface ProfileProps {
}

const UserProfile: React.FC<ProfileProps> = ({ }: ProfileProps) => {
    const isMobile = useMediaQuery("(max-width:578px)");

    // handle Form field
    const [profile, setProfile] = useState<File | string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(false)

    const [errors, setErrors] = useState({
        profile: "",
        name: "",
        email: "",
    });

    const formIsValid = () => {
        const newErrors = {
            profile: !profile ? "Logo is required" : "",
            email: !email?.trim() ? "Email is required" : "",
            name: !name?.trim() ? "Name is required" : "",
        };
        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === "");
    };


    //params 
    const params = useParams()
    const navigate = useNavigate()

    const handleUpdatePassword = () => {
        navigate(`/update-user-password/${params?.id}`)
    }

    const goBack = () => {
        navigate("/")
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const data = {
            profile, name
        }
        if (formIsValid()) {
            setLoading(true)
            const response = await multiPartPUTRequest(updateUserProfile(params?.id), data)
            const updateData = {
                name: response?.data?.data?.name,
                profile: response?.data?.data?.profile
            }
            updateUserData(updateData)
            setLoading(false)
            Toast(response?.data?.statusMessage)
            navigate("/")
        }
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            if (event.target.files && event.target.files.length > 0) {
                setProfile(event.target.files[0]);
            }
        }
    };

    const handleClearImage = () => {
        setProfile(null);
    };

    const getUserData = async () => {
        setFetchLoading(true)
        const response = await getRequest(getUserProfile(params?.id))
        setProfile(response?.data?.data?.user?.profile);
        setEmail(response?.data?.data?.user?.email)
        setName(response?.data?.data?.user?.name)
        setFetchLoading(false)
    }

    useEffect(() => {
        getUserData()
    }, [])

    const handleGridClick = () => {
        document.getElementById("file-upload")?.click();
    };

    return fetchLoading ? <Loading /> : (
        <div>
            <Container
                maxWidth="md"
                style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid
                    spacing={2}
                    bgcolor="#FFFFFF"
                    width="784px"
                    borderRadius="22px"
                    border="1px solid #D6D6D7"
                    margin={0}
                >
                    <Grid
                        item
                        height={isMobile ? "auto" : "auto"}
                        style={{ padding: 30 }}
                    >
                        <div className="col-12 mb-1">
                            <ArrowBackIcon onClick={goBack} style={{cursor:'pointer'}} />
                        </div>  
                        <Grid
                            container
                            direction="row"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={isMobile ? "10px" : ""}
                        >
                            <Grid item xs={8}>
                                <Typography
                                    className="profile-title"
                                    color="#273848"
                                    fontWeight={700}
                                    fontSize="20.94px"
                                >
                                    Update Your Profile
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid my={2}></Grid>
                        <Grid item>
                            <Typography className="input-label-custom">
                                Profile Picture <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Grid mb={1}></Grid>
                            <Grid
                                item
                                xs={8}
                                border="1px solid #D5D5D5"
                                direction="column"
                                justifyContent="space-between"
                                alignItems="center"
                                display="flex"
                                borderRadius="20px"
                                padding={isMobile ? "30px" : "18px 230px 48px 230px"}
                                onClick={handleGridClick}
                                style={{ cursor: "pointer", position: "relative" }}
                            >
                                {profile ? (
                                    <>
                                        {typeof profile === "string" ? (
                                            <>
                                                <img
                                                    src={profile}
                                                    alt="selected-image"
                                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                />
                                                <CloseIcon
                                                    onClick={handleClearImage}
                                                    style={{
                                                        position: "absolute",
                                                        marginRight: "-40%",
                                                        cursor: "pointer",
                                                        color: "#4E4E4E",
                                                    }}
                                                />
                                            </>
                                        )
                                            : (
                                                <><img
                                                    src={URL.createObjectURL(profile)}
                                                    alt="selected-image"
                                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                />
                                                    <CloseIcon
                                                        onClick={handleClearImage}
                                                        style={{
                                                            position: "absolute",
                                                            marginRight: "-40%",
                                                            cursor: "pointer",
                                                            color: "#4E4E4E",
                                                        }}
                                                    />
                                                </>
                                            )}

                                    </>
                                ) : (
                                    <>
                                        <img src={uploadFileIcon} alt="uploadFile-Icon" />
                                        <Grid my={1}></Grid>
                                        <Typography align="center" color="#4E4E4E">
                                            Drag a file or{" "}
                                            <Link
                                                // href="/signin"
                                                color="#4E4E4E"
                                                style={{ textDecorationColor: "#4E4E4E" }}
                                            >
                                                select
                                            </Link>
                                        </Typography>
                                    </>
                                )}
                                <input
                                    id="file-upload"
                                    type="file"
                                    style={{ display: "none" }}
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </Grid>
                            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.profile}</InputLabel>
                        </Grid>
                        <Grid my={2}></Grid>
                        <Grid item>
                            <Typography className="input-label-custom">
                                Name<span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Grid mb={1}></Grid>
                            <TextField
                                sx={{
                                    // mt: 1,
                                    "& .MuiOutlinedInput-input": {
                                      padding: "10px 11px", // Add padding here
                                    },
                                  }}
                                placeholder="Name"
                                fullWidth
                                className="input-box-custom"
                                error={!!errors.name}
                                helperText={errors.name}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid my={2}></Grid>
                        <Grid item>
                            <Typography className="input-label-custom">
                                Email<span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Grid mb={1}></Grid>
                            <TextField
                                sx={{
                                    // mt: 1,
                                    "& .MuiOutlinedInput-input": {
                                      padding: "10px 11px", // Add padding here
                                    },
                                  }}
                                disabled
                                placeholder="Email"
                                fullWidth
                                className="input-box-custom"
                                error={!!errors.email}
                                helperText={errors.email}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid my={4}></Grid>
                        <Grid
                            container
                            direction="row"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={3} style={{ maxWidth: "fitContent" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    style={{
                                        backgroundColor: "#84E1CC",
                                        boxShadow: "none",
                                        borderRadius: "5px",
                                        padding: "17px 17px",
                                        lineHeight: "0px",
                                        textTransform: "none",
                                        letterSpacing: "0px",
                                    }}
                                    onClick={handleUpdatePassword}
                                >
                                    Update Password
                                </Button>
                            </Grid>
                            <Grid item xs={3} style={{ maxWidth: "fitContent" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    style={{
                                        backgroundColor: "#84E1CC",
                                        boxShadow: "none",
                                        borderRadius: "5px",
                                        padding: "17px 17px",
                                        lineHeight: "0px",
                                        textTransform: "none",
                                        letterSpacing: "0px",
                                    }}
                                    onClick={(event) => handleSubmit(event)}
                                >
                                    {loading ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default UserProfile;
