import React from "react";
import Label from "../../components/Common/Label";
import FilterIcon from '../../assets/images/filtericon.svg'
import CallIcon from '../../assets/images/callicon.svg'
import MailIcon from '../../assets/images/mailIcon.svg'
import FileIcon from '../../assets/images/fileIcon.svg'
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:780px)');

    const viewProfile = () => navigate('/candidate-leads-dashboard/view');

    return (
        <>
            <div className="container-fluid" style={{ padding: '32px 0px' }}>
                <div className="row">
                    <Label
                        children='Candidate List'
                        fontWeight={700}
                        fontSize='20.94px'
                        color="#273848"
                    />
                    <Label
                        children='Lorem ipsum dolor sit amet consectetur. Tortor odio.'
                        fontWeight={500}
                        fontSize='12.94px'
                        color="#273848"
                    />
                    <div className="d-flex align-items-center justify-content-between" style={{ marginTop: '25px' }}>
                        <Label
                            children='Campaign Name'
                            fontWeight={700}
                            fontSize='17.94px'
                            color="#273848"
                        />
                        <div className="d-flex align-items-center justify-content-center user"
                            style={{
                                gap: '10px',
                                border: '1px solid #273848',
                                padding: '6px 14px',
                                borderRadius: '6px',
                                cursor: 'pointer'
                            }}>
                            <img src={FilterIcon} alt="FilterIcon" />
                            <Label
                                children='Filters'
                                fontWeight={500}
                                fontSize='12.94px'
                                color="#273848"
                            />
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center flex-column row" style={{ marginTop: '29px', gap: '26px' }}>

                        <div
                            style={{
                                backgroundColor: 'white',
                                border: '1px solid #d6d6d7',
                                borderRadius: '27px',
                                padding: '30px 33px'
                            }}>
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-start" style={{ position: 'relative', borderRight: matches ? '1px solid #DADaDA' : 'none' }}>
                                    <div className="d-flex align-items-center justify-content-start" style={{ gap: '17px' }}>
                                        <img src="https://randomuser.me/api/portraits/women/66.jpg" className="img-fluid" style={{ height: '66px', width: '66px', borderRadius: '50%', objectFit: 'cover' }} alt="USERIMAGE" />
                                        <div>
                                            <Label
                                                children="Full Name"
                                                fontWeight={700}
                                                fontSize={'17.48px'}
                                                color={"#273848"}
                                            />
                                            <Label
                                                children="Job Title"
                                                fontWeight={500}
                                                fontSize={'13.85px'}
                                                color={"#273848"}
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: '-42.5px',
                                        left: '21px',
                                        backgroundColor: '#81e1cc',
                                        color: 'white',
                                        padding: '4px 30px',
                                        borderRadius: '5px',
                                        border: 'none'
                                    }}>
                                        <Label
                                            children='Contacted'
                                            fontWeight={700}
                                            fontSize='11.94px'
                                            color="white"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="d-flex align-items-center justify-content-between w-100" style={{ padding: '0px 30px' }}>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={CallIcon} className="img-fluid" alt="CallIcon" />
                                            <Label
                                                children='+123456789'
                                                fontWeight={500}
                                                fontSize='16.85px'
                                                color="#273848"
                                            />
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={MailIcon} className="img-fluid" alt="MailIcon" />
                                            <Label
                                                children='info@test.it'
                                                fontWeight={500}
                                                fontSize='16.85px'
                                                color="#273848"
                                            />
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={FileIcon} className="img-fluid" alt="FileIcon" />
                                            <Link to={'/'} style={{ color: '#273848' }}>
                                                <Label
                                                    children='View CV'
                                                    fontWeight={500}
                                                    fontSize='16.85px'
                                                    color="#273848"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 d-flex align-items-center justify-content-end" style={{ borderLeft: matches ? '1px solid #DADaDA' : 'none' }}>
                                    <button className="user" style={{
                                        fontWeight: 500,
                                        fontSize: '15.94px',
                                        backgroundColor: '#81e1cc',
                                        color: '#ffffff',
                                        padding: '8px 20px',
                                        borderRadius: '5px',
                                        border: 'none'
                                    }} onClick={viewProfile}>
                                        Call to Action
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                backgroundColor: 'white',
                                border: '1px solid #d6d6d7',
                                borderRadius: '27px',
                                padding: '30px 33px'
                            }}>
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-start" style={{ position: 'relative', borderRight: matches ? '1px solid #DADaDA' : 'none' }}>
                                    <div className="d-flex align-items-center justify-content-start" style={{ gap: '17px' }}>
                                        <img src="https://randomuser.me/api/portraits/women/66.jpg" className="img-fluid" style={{ height: '66px', width: '66px', borderRadius: '50%', objectFit: 'cover' }} alt="USERIMAGE" />
                                        <div>
                                            <Label
                                                children="Full Name"
                                                fontWeight={700}
                                                fontSize={'17.48px'}
                                                color={"#273848"}
                                            />
                                            <Label
                                                children="Job Title"
                                                fontWeight={500}
                                                fontSize={'13.85px'}
                                                color={"#273848"}
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: '-42.5px',
                                        left: '21px',
                                        backgroundColor: '#81e1cc',
                                        color: 'white',
                                        padding: '4px 30px',
                                        borderRadius: '5px',
                                        border: 'none'
                                    }}>
                                        <Label
                                            children='Waiting on reply'
                                            fontWeight={700}
                                            fontSize='11.94px'
                                            color="white"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="d-flex align-items-center justify-content-between w-100" style={{ padding: '0px 30px' }}>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={CallIcon} className="img-fluid" alt="CallIcon" />
                                            <Label
                                                children='+123456789'
                                                fontWeight={500}
                                                fontSize='16.85px'
                                                color="#273848"
                                            />
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={MailIcon} className="img-fluid" alt="MailIcon" />
                                            <Label
                                                children='info@test.it'
                                                fontWeight={500}
                                                fontSize='16.85px'
                                                color="#273848"
                                            />
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={FileIcon} className="img-fluid" alt="FileIcon" />
                                            <Link to={'/'} style={{ color: '#273848' }}>
                                                <Label
                                                    children='View CV'
                                                    fontWeight={500}
                                                    fontSize='16.85px'
                                                    color="#273848"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 d-flex align-items-center justify-content-end" style={{ borderLeft: matches ? '1px solid #DADaDA' : 'none' }}>
                                    <button className="user" style={{
                                        fontWeight: 500,
                                        fontSize: '15.94px',
                                        backgroundColor: '#81e1cc',
                                        color: '#ffffff',
                                        padding: '8px 20px',
                                        borderRadius: '5px',
                                        border: 'none'
                                    }} onClick={viewProfile}>
                                        Call to Action
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                backgroundColor: 'white',
                                border: '1px solid #d6d6d7',
                                borderRadius: '27px',
                                padding: '30px 33px'
                            }}>
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-start" style={{ position: 'relative', borderRight: matches ? '1px solid #DADaDA' : 'none' }}>
                                    <div className="d-flex align-items-center justify-content-start" style={{ gap: '17px' }}>
                                        <img src="https://randomuser.me/api/portraits/women/66.jpg" className="img-fluid" style={{ height: '66px', width: '66px', borderRadius: '50%', objectFit: 'cover' }} alt="USERIMAGE" />
                                        <div>
                                            <Label
                                                children="Full Name"
                                                fontWeight={700}
                                                fontSize={'17.48px'}
                                                color={"#273848"}
                                            />
                                            <Label
                                                children="Job Title"
                                                fontWeight={500}
                                                fontSize={'13.85px'}
                                                color={"#273848"}
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: '-42.5px',
                                        left: '21px',
                                        backgroundColor: '#81e1cc',
                                        color: 'white',
                                        padding: '4px 30px',
                                        borderRadius: '5px',
                                        border: 'none'
                                    }}>
                                        <Label
                                            children='Booked meeting'
                                            fontWeight={700}
                                            fontSize='11.94px'
                                            color="white"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="d-flex align-items-center justify-content-between w-100" style={{ padding: '0px 30px' }}>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={CallIcon} className="img-fluid" alt="CallIcon" />
                                            <Label
                                                children='+123456789'
                                                fontWeight={500}
                                                fontSize='16.85px'
                                                color="#273848"
                                            />
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={MailIcon} className="img-fluid" alt="MailIcon" />
                                            <Label
                                                children='info@test.it'
                                                fontWeight={500}
                                                fontSize='16.85px'
                                                color="#273848"
                                            />
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={FileIcon} className="img-fluid" alt="FileIcon" />
                                            <Link to={'/'} style={{ color: '#273848' }}>
                                                <Label
                                                    children='View CV'
                                                    fontWeight={500}
                                                    fontSize='16.85px'
                                                    color="#273848"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 d-flex align-items-center justify-content-end" style={{ borderLeft: matches ? '1px solid #DADaDA' : 'none' }}>
                                    <button className="user" style={{
                                        fontWeight: 500,
                                        fontSize: '15.94px',
                                        backgroundColor: '#81e1cc',
                                        color: '#ffffff',
                                        padding: '8px 20px',
                                        borderRadius: '5px',
                                        border: 'none'
                                    }} onClick={viewProfile}>
                                        Call to Action
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                backgroundColor: 'white',
                                border: '1px solid #d6d6d7',
                                borderRadius: '27px',
                                padding: '30px 33px'
                            }}>
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-start" style={{ position: 'relative', borderRight: matches ? '1px solid #DADaDA' : 'none' }}>
                                    <div className="d-flex align-items-center justify-content-start" style={{ gap: '17px' }}>
                                        <img src="https://randomuser.me/api/portraits/women/66.jpg" className="img-fluid" style={{ height: '66px', width: '66px', borderRadius: '50%', objectFit: 'cover' }} alt="USERIMAGE" />
                                        <div>
                                            <Label
                                                children="Full Name"
                                                fontWeight={700}
                                                fontSize={'17.48px'}
                                                color={"#273848"}
                                            />
                                            <Label
                                                children="Job Title"
                                                fontWeight={500}
                                                fontSize={'13.85px'}
                                                color={"#273848"}
                                            />
                                        </div>
                                    </div>
                                    <div style={{
                                        position: 'absolute',
                                        top: '-42.5px',
                                        left: '21px',
                                        backgroundColor: '#81e1cc',
                                        color: 'white',
                                        padding: '4px 30px',
                                        borderRadius: '5px',
                                        border: 'none'
                                    }}>
                                        <Label
                                            children='Declined'
                                            fontWeight={700}
                                            fontSize='11.94px'
                                            color="white"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 d-flex align-items-center justify-content-center">
                                    <div className="d-flex align-items-center justify-content-between w-100" style={{ padding: '0px 30px' }}>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={CallIcon} className="img-fluid" alt="CallIcon" />
                                            <Label
                                                children='+123456789'
                                                fontWeight={500}
                                                fontSize='16.85px'
                                                color="#273848"
                                            />
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={MailIcon} className="img-fluid" alt="MailIcon" />
                                            <Label
                                                children='info@test.it'
                                                fontWeight={500}
                                                fontSize='16.85px'
                                                color="#273848"
                                            />
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                            <img src={FileIcon} className="img-fluid" alt="FileIcon" />
                                            <Link to={'/'} style={{ color: '#273848' }}>
                                                <Label
                                                    children='View CV'
                                                    fontWeight={500}
                                                    fontSize='16.85px'
                                                    color="#273848"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 d-flex align-items-center justify-content-end" style={{ borderLeft: matches ? '1px solid #DADaDA' : 'none' }}>
                                    <button className="user" style={{
                                        fontWeight: 500,
                                        fontSize: '15.94px',
                                        backgroundColor: '#81e1cc',
                                        color: '#ffffff',
                                        padding: '8px 20px',
                                        borderRadius: '5px',
                                        border: 'none'
                                    }} onClick={viewProfile}>
                                        Call to Action
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Dashboard;