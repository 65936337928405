import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextField,
  Link,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import CloseIcon from "@mui/icons-material/Close";
import uploadFileIcon from "../../assets/images/uploadFileIcon.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { multiPartPUTRequest } from "../../api/apiServices";
import { updateCompany } from "../../api/apiList";
import { useNavigate, useParams } from "react-router-dom";

interface ProfileProps {
  onNext: () => void; // Function to navigate to the next component
  setProfileData: (data: any) => void;
  initialData: any;
  mode: any
}

const ProfileFirst: React.FC<ProfileProps> = ({ onNext, initialData, setProfileData, mode }: ProfileProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");

  // handle Form field
  const [logo, setLogo] = useState<File | string | null>(null);
  const [primaryColor, setPrimaryColor] = useState<string | null>(null);
  const [webUrl, setWebUrl] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState({
    logo: "",
    primaryColor: "",
    webUrl: "",
    name: "",
    city: "",
    state: "",
    country: "",
    zipCode: ""
  });

  const formIsValid = () => {
    const newErrors = {
      logo: !logo ? "Logo is required" : "",
      primaryColor: !primaryColor?.trim() ? "Select Colour is required" : "",
      webUrl: !webUrl?.trim() ? "WebUrl is required" : "",
      name: !name?.trim() ? "Comapnay Name is required" : "",
      city: !city?.trim() ? "City Name is required" : "",
      state: !state?.trim() ? "State Name is required" : "",
      country: !country?.trim() ? "Country Name is required" : "",
      zipCode: !zipCode?.trim() ? "ZipCode Name is required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };


  //params 
  const params = useParams()
  const navigate = useNavigate()

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      logo,
      primaryColor,
      webUrl,
      name,
      city,
      state,
      country,
      zipCode,
    }
    if (formIsValid()) {
      if (mode) {
        setLoading(true)
        const response = await multiPartPUTRequest(updateCompany(params?.id), data)
        setProfileData(response?.data?.data)
        setLoading(false)
        onNext()
      } else {
        const allData = {
          ...initialData,
          ...data,
        };
        setProfileData(allData)
        onNext(); // Call the onNext function to navigate to the next component
      }
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files && event.target.files.length > 0) {
        setLogo(event.target.files[0]);
      }
    }
  };

  const handleClearImage = () => {
    setLogo(null);
  };

  const goBack = () => {
    navigate("/")
  }

  useEffect(() => {
    if (initialData) {
      setName(initialData?.name)
      setCity(initialData?.city)
      setState(initialData?.state)
      setCountry(initialData?.country)
      setZipCode(initialData?.zipCode)
      setLogo(initialData?.logo)
      setPrimaryColor(initialData?.primaryColor)
      setWebUrl(initialData?.webUrl)
    }
  }, [])

  const handleGridClick = () => {
    document.getElementById("file-upload")?.click();
  };

  const handleColorClick = (color: string) => {
    setPrimaryColor(color === primaryColor ? null : color);
  };

  return (
    <div>
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          padding: '20px',
          justifyContent: "center",
          alignItems: "center",
        }}

      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="784px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <div className="col-12 mb-1">
              <ArrowBackIcon onClick={goBack} style={{ cursor: 'pointer' }} />
            </div>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item xs={8}>
                <Typography
                  className="profile-title"
                  color="#273848"
                  fontWeight={700}
                  fontSize="20.94px"
                >
                  Create Profile
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Company Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Company Name"
                fullWidth
                className="input-box-custom"
                error={!!errors.name}
                helperText={errors.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Logo <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <Grid
                item
                xs={8}
                border="1px solid #D5D5D5"
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                borderRadius="20px"
                padding={isMobile ? "30px" : "18px 230px 48px 230px"}
                onClick={handleGridClick}
                style={{ cursor: "pointer", position: "relative" }}
              >
                {logo ? (
                  <>
                    {typeof logo === "string" ? (
                      <>
                        <img
                          src={logo}
                          alt="selected-image"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                        <CloseIcon
                          onClick={handleClearImage}
                          style={{
                            position: "absolute",
                            marginRight: "-40%",
                            cursor: "pointer",
                            color: "#4E4E4E",
                          }}
                        />
                      </>
                    )
                      : (
                        <><img
                          src={URL.createObjectURL(logo)}
                          alt="selected-image"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                          <CloseIcon
                            onClick={handleClearImage}
                            style={{
                              position: "absolute",
                              marginRight: "-40%",
                              cursor: "pointer",
                              color: "#4E4E4E",
                            }}
                          />
                        </>
                      )}

                  </>
                ) : (
                  <>
                    <img src={uploadFileIcon} alt="uploadFile-Icon" />
                    <Grid my={1}></Grid>
                    <Typography align="center" color="#4E4E4E">
                      Drag a file or{" "}
                      <Link
                        // href="/signin"
                        color="#4E4E4E"
                        style={{ textDecorationColor: "#4E4E4E" }}
                      >
                        select
                      </Link>
                    </Typography>
                  </>
                )}
                <input
                  id="file-upload"
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Grid>
              <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.logo}</InputLabel>
            </Grid>
            <Grid my={2}></Grid>
            <Grid
              item
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className="input-label-custom">
                Select Primary Colors <span style={{ color: "red" }}>*</span>
              </Typography>

              <Grid item display="flex" flexDirection="row">
                <Grid
                  justifyContent="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={() => handleColorClick("#4E4E4E")}
                  style={{
                    cursor: "pointer",
                    border:
                      primaryColor === "#4E4E4E"
                        ? "2px solid #4E4E4E"
                        : "2px solid transparent",
                    borderRadius: "4px",
                    padding: "5px",
                  }}
                >
                  <Box
                    width="35px"
                    height="35px"
                    sx={{
                      borderRadius: "5px",
                      bgcolor: "#D9D9D9",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
                <Grid mx={1} />
                <Grid
                  justifyContent="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={() => handleColorClick("#453939")}
                  style={{
                    cursor: "pointer",
                    border:
                      primaryColor === "#453939"
                        ? "2px solid #4E4E4E"
                        : "2px solid transparent",
                    borderRadius: "4px",
                    padding: "5px",
                  }}
                >
                  <Box
                    width="35px"
                    height="35px"
                    sx={{
                      borderRadius: "5px",
                      bgcolor: "#453939",
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.primaryColor}</InputLabel>
            <Grid item>
              <Typography className="input-label-custom">
                Website URL<span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Website URL"
                fullWidth
                className="input-box-custom"
                error={!!errors.webUrl}
                helperText={errors.webUrl}
                value={webUrl}
                onChange={(e) => setWebUrl(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "15px"
              }}
            >
              <Grid item style={{ width: "100%", marginLeft: "2px" }}>
                <Typography className="input-label-custom">
                  City <span style={{ color: "red" }}>*</span>
                </Typography>
                <Grid mb={1}></Grid>
                <TextField
                  sx={{
                    // mt: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 11px", // Add padding here
                    },
                  }}
                  placeholder="Enter City Name"
                  fullWidth
                  className="input-box-custom"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.city}</InputLabel>
              </Grid>
              <Grid item style={{ width: "100%", marginLeft: "5px" }}>
                <Typography className="input-label-custom">
                  State <span style={{ color: "red" }}>*</span>
                </Typography>
                <Grid mb={1}></Grid>
                <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                  name="workHours"
                  placeholder="Enter State Name"
                  fullWidth
                  className="input-box-custom"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
                <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.state}</InputLabel>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "15px"
              }}
            >
              <Grid item style={{ width: "100%", marginLeft: "2px" }}>
                <Typography className="input-label-custom">
                  Country <span style={{ color: "red" }}>*</span>
                </Typography>
                <Grid mb={1}></Grid>
                <TextField
                  sx={{
                    // mt: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 11px", // Add padding here
                    },
                  }}
                  placeholder="Enter Country Name"
                  fullWidth
                  className="input-box-custom"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
                <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.country}</InputLabel>
              </Grid>
              <Grid item style={{ width: "100%", marginLeft: "5px" }}>
                <Typography className="input-label-custom">
                  Zip Code <span style={{ color: "red" }}>*</span>
                </Typography>
                <Grid mb={1}></Grid>
                <TextField
                  sx={{
                    // mt: 1,
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 11px", // Add padding here
                    },
                  }}
                  name="workHours"
                  placeholder="Enter Zip Code"
                  fullWidth
                  className="input-box-custom"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
                <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.zipCode}</InputLabel>
              </Grid>
            </Grid>
            <Grid mt="35px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid></Grid>
              <Grid item xs={3} style={{ maxWidth: "fitContent" }}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? loading ? <CircularProgress size={20} color="inherit" /> : "Save & Next" : "Continue"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ProfileFirst;
