import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  TextField,
  InputLabel,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import WorkSharpIcon from "../../assets/images/CandidateIcon/work-sharp-color.svg";
import SkillIcon from "../../assets/images/CandidateIcon/skill-level-color.svg";
import { useParams } from "react-router-dom";
import { putRequest } from "../../api/apiServices";
import { updateCandidate } from "../../api/apiList";

interface CandidateSpeSecondProps {
  onNext: () => void; // Function to navigate to the next component
  onBack: () => void;
  setCandidateData: (data: any) => void;
  initialData: any;
  mode: any;
}

const CandidateSpeSecond: React.FC<CandidateSpeSecondProps> = ({ onNext, onBack, setCandidateData, initialData, mode }: CandidateSpeSecondProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");

  // handle to form fields
  const [workingHours, setWorkingHours] = useState("")
  const [project, setProject] = useState("")
  const [specificChallenges, setSpecificChallenges] = useState("")
  const [currentResponsibilities, setCurrentResponsibilities] = useState("")
  const [educationalBackground, setEducationalBackground] = useState("")
  const [courceCertificates, setCourceCertificates] = useState("")
  const [technicalSkills, setTechnicalSkills] = useState("")
  const [previousPositionTechnicalSkills, setPreviousPositionTechnicalSkills] = useState("")
  const [loading,setLoading] = useState(false)

  //params 
  const params = useParams()

  const [errors, setErrors] = useState({
    workingHours: "",
    project: "",
    specificChallenges: "",
    currentResponsibilities: "",
    educationalBackground: "",
    courceCertificates: "",
    technicalSkills: "",
    previousPositionTechnicalSkills: "",
  });

  const formIsValid = () => {
    const newErrors = {
      workingHours: !workingHours?.trim() ? "Working Hours is required" : "",
      project: !project?.trim() ? "Key Achievements Or Projects is required" : "",
      specificChallenges: !specificChallenges?.trim() ? "Specific Challenges faced In Your Previous Positions And How You Overcame is required" : "",
      currentResponsibilities: !currentResponsibilities?.trim() ? "Current Role & Responsibilities is required" : "",
      educationalBackground: !educationalBackground?.trim() ? "Educational Background is required" : "",
      courceCertificates: !courceCertificates?.trim() ? "Cource & Certificates is required" : "",
      technicalSkills: !technicalSkills?.trim() ? "Technical Skills is required" : "",
      previousPositionTechnicalSkills: !previousPositionTechnicalSkills?.trim() ? "Technical Skills In Previous Positions is required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  useEffect(() => {
    if (initialData) {
      setWorkingHours(initialData?.workingHours)
      setProject(initialData?.project)
      setSpecificChallenges(initialData?.specificChallenges)
      setCurrentResponsibilities(initialData?.currentResponsibilities)
      setEducationalBackground(initialData?.educationalBackground)
      setCourceCertificates(initialData?.courceCertificates)
      setTechnicalSkills(initialData?.technicalSkills)
      setPreviousPositionTechnicalSkills(initialData?.previousPositionTechnicalSkills)
    }
  }, [])


  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      workingHours,
      project,
      specificChallenges,
      currentResponsibilities,
      educationalBackground,
      courceCertificates,
      technicalSkills,
      previousPositionTechnicalSkills
    }
    if (formIsValid()) {
      if (mode) {
        setLoading(true)
        const response = await putRequest(updateCandidate(params?.id), data)
        setCandidateData(response?.data?.data)
        setLoading(false)
        onNext()
      } else {


        const allData = {
          ...initialData,
          ...data,
        };
        setCandidateData(allData)
        onNext();
      }
    }

  };

  const handleBack = (event: any) => {
    event.preventDefault();
    onBack(); // Call the onNext function to navigate to the next component
  };

  return (
    <div>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "25px",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item width="fit-content">
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Current Situation & Practicalities
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt="18px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            //   className="input-label-custom"
            >
              Number of Working Hours
            </Typography>
            <Grid mt="15px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What are your expectations regarding working hours and
                flexibility in this role?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What are your expectations regarding working hours and flexibility in this role?"
                fullWidth
                className="input-box-custom"
                error={!!errors.workingHours}
                helperText={errors.workingHours}
                value={workingHours}
                onChange={(e) => setWorkingHours(e.target.value)}
              />
            </Grid>
            <Grid mt="18px"></Grid>
            <Grid
              display="flex"
              alignItems="center"
              border="1px solid #c3c3c3"
              borderRadius="8px"
              padding="8px 0px 6px 15px"
            >
              <Grid direction="row" display="flex" gap={1} alignItems="center">
                <img src={WorkSharpIcon} alt="overview-icon" />
                <Typography
                  //   className="input-label-custom"
                  fontWeight={700}
                  fontSize="13.94px"
                  lineHeight="13.94px"
                  color="#273848"
                >
                  Experience & Education
                </Typography>
              </Grid>
            </Grid>
            <Grid mt="31px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            //   className="input-label-custom"
            >
              Work Experience
            </Typography>
            <Grid mt="15px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What are some key achievements or projects from your previous
                roles?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What are some key achievements or projects from your previous roles?"
                fullWidth
                className="input-box-custom"
                error={!!errors.project}
                helperText={errors.project}
                value={project}
                onChange={(e) => setProject(e.target.value)}
              />
            </Grid>
            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Can you discuss any specific challenges you've faced in your
                previous positions and how you overcame them?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Can you discuss any specific challenges you've faced in your previous positions and how you overcame them?"
                fullWidth
                className="input-box-custom"
                error={!!errors.specificChallenges}
                helperText={errors.specificChallenges}
                value={specificChallenges}
                onChange={(e) => setSpecificChallenges(e.target.value)}
              />
            </Grid>
            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                How does your current role prepare you for the responsibilities
                of this vacancy?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="How does your current role prepare you for the responsibilities of this vacancy?"
                fullWidth
                className="input-box-custom"
                error={!!errors.currentResponsibilities}
                helperText={errors.currentResponsibilities}
                value={currentResponsibilities}
                onChange={(e) => setCurrentResponsibilities(e.target.value)}
              />
            </Grid>
            <Grid mt="32px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            //   className="input-label-custom"
            >
              Education & Courses
            </Typography>
            <Grid mt="18px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What is your educational background, and how does it relate to
                this position?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={educationalBackground}
                onChange={(e) => setEducationalBackground(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.educationalBackground}</InputLabel>

            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Have you pursued any relevant courses or certifications
                recently?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Have you pursued any relevant courses or certifications recently?"
                fullWidth
                className="input-box-custom"
                error={!!errors.courceCertificates}
                helperText={errors.courceCertificates}
                value={courceCertificates}
                onChange={(e) => setCourceCertificates(e.target.value)}
              />
            </Grid>
            <Grid mt="23px"></Grid>
            <Grid
              display="flex"
              alignItems="center"
              border="1px solid #c3c3c3"
              borderRadius="8px"
              padding="8px 0px 6px 15px"
            >
              <Grid direction="row" display="flex" gap={1} alignItems="center">
                <img src={SkillIcon} alt="skill-icon" />
                <Typography
                  //   className="input-label-custom"
                  fontWeight={700}
                  fontSize="13.94px"
                  lineHeight="13.94px"
                  color="#273848"
                >
                  Skills & Traits
                </Typography>
              </Grid>
            </Grid>
            <Grid mt="18px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            //   className="input-label-custom"
            >
              Hard Skills
            </Typography>
            <Grid mt="18px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What technical skills or expertise do you bring to this role?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What technical skills or expertise do you bring to this role?"
                fullWidth
                className="input-box-custom"
                error={!!errors.technicalSkills}
                helperText={errors.technicalSkills}
                value={technicalSkills}
                onChange={(e) => setTechnicalSkills(e.target.value)}
              />
            </Grid>
            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Can you provide examples of how you've applied your technical
                skills in previous positions?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={previousPositionTechnicalSkills}
                onChange={(e) => setPreviousPositionTechnicalSkills(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.previousPositionTechnicalSkills}</InputLabel>
            <Grid mt="26px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                    width:'100%'
                    // padding: "10px 50px",
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </button>
              </Grid>
              <Grid item xs={mode ? 3 : 2} style={{ maxWidth: "fitContent" }}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    color:'white',
                    width:'100%',
                    border:'none'
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? loading ?<CircularProgress size={20}  color="inherit"/>   : "Save & Next" : "Continue"}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CandidateSpeSecond;
