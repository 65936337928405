import React from "react";
import Label from "../../components/Common/Label";
import CallIcon from '../../assets/images/callicon.svg'
import MailIcon from '../../assets/images/mailIcon.svg'
import FileIcon from '../../assets/images/fileIcon.svg'
import MeetIcon from '../../assets/images/Dashboard/meet.svg'
import StarIcon from '../../assets/images/Dashboard/star.svg'
import UmbrellaIcon from '../../assets/images/Dashboard/umbrella.svg'
import InnfoIcon from '../../assets/images/Dashboard/info.svg'
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

const DashboardView: React.FC = () => {
    const matches = useMediaQuery('(min-width:780px)');
    return (
        <div className="container-fluid" style={{ padding: '32px 0px' }}>
            <div className="d-flex align-items-center justify-content-center flex-column row" style={{ marginTop: '29px', gap: '26px' }}>

                <div
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid #d6d6d7',
                        borderRadius: '27px',
                        padding: '30px 33px'
                    }}>
                    <div className="row">
                        <div className="col-md-3 d-flex align-items-center justify-content-start" style={{ position: 'relative', borderRight: matches ? '1px solid #DADaDA' : 'none' }}>
                            <div className="d-flex align-items-center justify-content-start" style={{ gap: '17px' }}>
                                <img src="https://randomuser.me/api/portraits/women/66.jpg" className="img-fluid" style={{ height: '66px', width: '66px', borderRadius: '50%', objectFit: 'cover' }} alt="USERIMAGE" />
                                <div>
                                    <Label
                                        children="Full Name"
                                        fontWeight={700}
                                        fontSize={'17.48px'}
                                        color={"#273848"}
                                    />
                                    <Label
                                        children="Job Title"
                                        fontWeight={500}
                                        fontSize={'13.85px'}
                                        color={"#273848"}
                                    />
                                </div>
                            </div>
                            <div style={{
                                position: 'absolute',
                                top: '-42.5px',
                                left: '21px',
                                backgroundColor: '#81e1cc',
                                color: 'white',
                                padding: '4px 30px',
                                borderRadius: '5px',
                                border: 'none'
                            }}>
                                <Label
                                    children='Contacted'
                                    fontWeight={700}
                                    fontSize='11.94px'
                                    color="white"
                                />
                            </div>
                        </div>

                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <div className="d-flex align-items-center justify-content-between w-100" style={{ padding: '0px 30px' }}>

                                <div className="d-flex align-items-center justify-content-center gap-1">
                                    <img src={CallIcon} className="img-fluid" alt="CallIcon" />
                                    <Label
                                        children='+123456789'
                                        fontWeight={500}
                                        fontSize='16.85px'
                                        color="#273848"
                                    />
                                </div>

                                <div className="d-flex align-items-center justify-content-center gap-1">
                                    <img src={MailIcon} className="img-fluid" alt="MailIcon" />
                                    <Label
                                        children='info@test.it'
                                        fontWeight={500}
                                        fontSize='16.85px'
                                        color="#273848"
                                    />
                                </div>

                                <div className="d-flex align-items-center justify-content-center gap-1">
                                    <img src={FileIcon} className="img-fluid" alt="FileIcon" />
                                    <Link to={'/'} style={{ color: '#273848' }}>
                                        <Label
                                            children='View CV'
                                            fontWeight={500}
                                            fontSize='16.85px'
                                            color="#273848"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Label
                    children='Select an Action'
                    fontWeight={700}
                    fontSize='20.48px'
                />

                <div className="row gap-5" style={{
                    backgroundColor: 'white',
                    border: '1px solid #d6d6d7',
                    borderRadius: '27px',
                    padding: '36px 23px',
                }}>
                    <div className="col" style={{ border: '1px solid #d6d6d7', borderRadius: '27px',padding:"27px", maxWidth:'300px' }}>
                        <div className="d-flex align-items-center justify-content-between flex-column" style={{ gap: '50px', height: '100%' }}>
                            <div className="d-flex align-items-center justify-content-center flex-column" style={{ gap: '20px' }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div style={{
                                        backgroundColor: '#EEF9FC',
                                        padding: '20px',
                                        borderRadius: '50%'
                                    }}>
                                        <img src={MeetIcon} alt="MeetIcon" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center flex-column">
                                    <Label
                                        children='Just meet'
                                        fontWeight={700}
                                        fontSize='16.48px'
                                        color="#273848"
                                    />
                                    <p className="m-0" style={{ fontWeight: 500, fontSize: '14.48px', color: '#273848', textAlign: 'center' }}>(Digital) Cup coffee (automated <br /> scheduling Calandy like)</p>
                                </div>
                            </div>
                            <button className="user" style={{
                                fontWeight: 500,
                                fontSize: '17.94px',
                                backgroundColor: '#84E1CC',
                                color: 'white',
                                padding: '7px 60px',
                                borderRadius: '5px',
                                border: 'none',

                            }}>
                                Select
                            </button>
                        </div>
                    </div>
                    <div className="col" style={{ border: '1px solid #d6d6d7', borderRadius: '27px',padding:"27px", maxWidth:'300px' }}>
                        <div className="d-flex align-items-center justify-content-between flex-column" style={{ gap: '50px', height: '100%' }}>
                            <div className="d-flex align-items-center justify-content-center flex-column" style={{ gap: '20px' }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div style={{
                                        backgroundColor: '#EEF9FC',
                                        padding: '20px',
                                        borderRadius: '50%'
                                    }}>
                                        <img style={{ height: '34px', width: '34px', objectFit: 'contain' }} src={StarIcon} alt="StarIcon" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center flex-column">
                                    <Label
                                        children='Not now'
                                        fontWeight={700}
                                        fontSize='16.48px'
                                        color="#273848"
                                    />
                                    <p className="m-0" style={{ fontWeight: 500, fontSize: '14.48px', color: '#273848', textAlign: 'center' }}>- When come back to you? <br />- In what circumstances will you <br />be interested?  <br />- Can we save your info?</p>
                                </div>
                            </div>
                            <button className="user" style={{
                                fontWeight: 500,
                                fontSize: '17.94px',
                                backgroundColor: '#84E1CC',
                                color: 'white',
                                padding: '7px 60px',
                                borderRadius: '5px',
                                border: 'none',

                            }}>
                                Select
                            </button>
                        </div>
                    </div>
                    <div className="col" style={{ border: '1px solid #d6d6d7', borderRadius: '27px',padding:"27px", maxWidth:'300px' }}>
                        <div className="d-flex align-items-center justify-content-between flex-column" style={{ gap: '50px', height: '100%' }}>
                            <div className="d-flex align-items-center justify-content-center flex-column" style={{ gap: '20px' }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div style={{
                                        backgroundColor: '#EEF9FC',
                                        padding: '20px',
                                        borderRadius: '50%'
                                    }}>
                                        <img style={{ height: '34px', width: '34px', objectFit: 'contain' }} src={UmbrellaIcon} alt="UmbrellaIcon" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center flex-column">
                                    <Label
                                        children='Interested in Vacancy'
                                        fontWeight={700}
                                        fontSize='16.48px'
                                        color="#273848"
                                    />
                                    <p className="m-0" style={{ fontWeight: 500, fontSize: '14.48px', color: '#273848', textAlign: 'center' }}>Interested in Vacancy</p>
                                </div>
                            </div>
                            <button className="user" style={{
                                fontWeight: 500,
                                fontSize: '17.94px',
                                backgroundColor: '#84E1CC',
                                color: 'white',
                                padding: '7px 60px',
                                borderRadius: '5px',
                                border: 'none',

                            }}>
                                Select
                            </button>
                        </div>
                    </div>
                    <div className="col" style={{ border: '1px solid #d6d6d7', borderRadius: '27px',padding:"27px", maxWidth:'300px' }}>
                        <div className="d-flex align-items-center justify-content-between flex-column" style={{ gap: '50px', height: '100%' }}>
                            <div className="d-flex align-items-center justify-content-center flex-column" style={{ gap: '20px' }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div style={{
                                        backgroundColor: '#EEF9FC',
                                        padding: '20px',
                                        borderRadius: '50%'
                                    }}>
                                        <img style={{ height: '34px', width: '34px', objectFit: 'contain' }} src={InnfoIcon} alt="InnfoIcon" />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center flex-column">
                                    <Label
                                        children='Need more info'
                                        fontWeight={700}
                                        fontSize='16.48px'
                                        color="#273848"
                                    />
                                    <p className="m-0" style={{ fontWeight: 500, fontSize: '14.48px', color: '#273848', textAlign: 'center' }}>- Career website <br />- Video <br />- Full Vacancy <br />- (Digital) Cup coffee with <br />Corporate Recruiter (automated <br />scheduling Calandy like) </p>
                                </div>
                            </div>
                            <button className="user" style={{
                                fontWeight: 500,
                                fontSize: '17.94px',
                                backgroundColor: '#84E1CC',
                                color: 'white',
                                padding: '7px 60px',
                                borderRadius: '5px',
                                border: 'none',

                            }}>
                                Select
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DashboardView;