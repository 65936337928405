import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  TextField,
  InputLabel,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import { useParams } from "react-router-dom";
import { putRequest } from "../../api/apiServices";
import { updateCandidate } from "../../api/apiList";

interface CandidateSpeThirdProps {
  onNext: () => void; // Function to navigate to the next component
  onBack: () => void;
  setCandidateData: (data: any) => void;
  initialData: any;
  mode: any;
}

const CandidateSpeThird: React.FC<CandidateSpeThirdProps> = ({ onNext, onBack, setCandidateData, initialData, mode }: CandidateSpeThirdProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");

  // handle form field
  const [deadlines, setDeadlines] = useState("")
  const [commonGoal, setCommonGoal] = useState("")
  const [workplace, setWorkplace] = useState("")
  const [teamMembersColleague, setTeamMembersColleague] = useState("")
  const [motivates, setMotivates] = useState("")
  const [loading,setLoading] = useState(false)

  //params 
  const params = useParams()

  const [errors, setErrors] = useState({
    deadlines: "",
    commonGoal: "",
    workplace: "",
    teamMembersColleague: "",
    motivates: "",
  });

  const formIsValid = () => {
    const newErrors = {
      deadlines: !deadlines?.trim() ? "Stress And Tight Deadlines is required" : "",
      commonGoal: !commonGoal?.trim() ? "Diverse Team To Achieve a Common Goal is required" : "",
      workplace: !workplace?.trim() ? "Conversation Or Conflict In The Workplace is required" : "",
      teamMembersColleague: !teamMembersColleague?.trim() ? "Describe Yourself as Team Member And a Colleagues is required" : "",
      motivates: !motivates?.trim() ? "Motivates You To Excel In Your Work is required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  useEffect(() => {
    if (initialData) {
      setDeadlines(initialData?.deadlines)
      setCommonGoal(initialData?.commonGoal)
      setWorkplace(initialData?.workplace)
      setTeamMembersColleague(initialData?.teamMembersColleague)
      setMotivates(initialData?.motivates)
    }
  }, [])

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      deadlines,
      commonGoal,
      workplace,
      teamMembersColleague,
      motivates
    }
    if (formIsValid()) {
      if (mode) {
        setLoading(true)
        const response = await putRequest(updateCandidate(params?.id), data)
        setCandidateData(response?.data?.data)
        setLoading(false)
        onNext()
      } else {
        const allData = {
          ...initialData,
          ...data,
        };
        setCandidateData(allData)
        onNext();
      }
    }
  };

  const handleBack = (event: any) => {
    event.preventDefault();
    // Handle form submission logic here
    onBack(); // Call the onNext function to navigate to the next component
  };

  return (
    <div >
      <Container
        style={{
          display: "flex",
          padding:'25px',
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item width="fit-content">
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Experience & Education
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt="28px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            //   className="input-label-custom"
            >
              Soft Skills
            </Typography>
            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                How do you handle stress and tight deadlines?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="How do you handle stress and tight deadlines?"
                fullWidth
                className="input-box-custom"
                error={!!errors.deadlines}
                helperText={errors.deadlines}
                value={deadlines}
                onChange={(e) => setDeadlines(e.target.value)}
              />
            </Grid>
            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Describe a situation where you had to collaborate with a diverse
                team to achieve a common goal.
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={commonGoal}
                onChange={(e) => setCommonGoal(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.commonGoal}</InputLabel>
            <Grid mt="23px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Can you share an example of when you had to navigate a difficult
                conversation or conflict in the workplace?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={workplace}
                onChange={(e) => setWorkplace(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.workplace}</InputLabel>
            <Grid mt="28px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            //   className="input-label-custom"
            >
              Personal Traits
            </Typography>
            <Grid mt="19px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                How would you describe yourself as a team member and a
                colleague?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={teamMembersColleague}
                onChange={(e) => setTeamMembersColleague(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.teamMembersColleague}</InputLabel>
            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What motivates you to excel in your work?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What motivates you to excel in your work?"
                fullWidth
                className="input-box-custom"
                error={!!errors.motivates}
                helperText={errors.motivates}
                value={motivates}
                onChange={(e) => setMotivates(e.target.value)}
              />
            </Grid>
            <Grid mt="81px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                    width:'100%'
                    // padding: "10px 50px",
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </button>
              </Grid>
              <Grid item xs={mode ? 3 : 2} style={{ maxWidth: "fitContent" }}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    color:'white',
                    width:'100%',
                    border:'none'
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? loading ?<CircularProgress size={20}  color="inherit"/>   : "Save & Next" : "Continue"}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    
    </div>
  );
};

export default CandidateSpeThird;
