import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  MenuItem,
  TextField,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import "../../styles/inputBox.css";
import Select from "@mui/material/Select";
import AiIcon from "../../assets/images/AiIcon.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputLabel } from "@material-ui/core";
import { generateText, generateTextObject } from "../../utils/AIGenerateText";
import CircularProgress from "@mui/material/CircularProgress";
import { getRequest, postRequest, putRequest } from "../../api/apiServices";
import {
  getHiringManagerList,
  getJobByName,
  getUserProfile,
  updateJob,
} from "../../api/apiList";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "../DailogBox";
import LoadingOverlay from "../../utils/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { fetchHiringManagers } from "../../store/hiringManagerSlice";
import { getToken } from "../../services/authService";

interface JobProfileFirstProps {
  onNext: () => void; // Function to navigate to the next component
  setFormData: (data: any) => void;
  initialData: any;
  mode: any;
}

interface Manager {
  id: string;
  name: string;
}

interface GeneratedTextResponse {
  [key: string]: string;
}

const JobProfileFirst: React.FC<JobProfileFirstProps> = ({
  onNext,
  setFormData,
  initialData,
  mode,
}: JobProfileFirstProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");

  // User Store
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //params
  const params = useParams();
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/jobs')
  }

  //decalare form data
  const [jobTitle, setJobTitle] = useState("");
  const [hiringManagerId, setHiringManagerId] = useState("");
  const [workingLocation, setWorkingLocation] = useState("");
  const [workLocationRatio, setWorkLocationRatio] = useState("");
  const [vacancyReason, setVacancyReason] = useState("");
  const [contractType, setContractType] = useState("");
  const [hoursPerWeek, setHoursPerWeek] = useState("");
  const [salaryRange, setSalaryRange] = useState("");
  const [benefitsWithThisRole, setBenefitsWithThisRole] = useState("");
  const [aiLoading, setAiLoading] = useState(false);
  const [loading, setLoading] = useState(false)

  const [otherValue, setOtherValue] = useState("");

  const contractTypes = ["Temporary", "Fixed", "Freelance", "Other"];

  const handleContractTypeChange = (event: any) => {
    const value = event.target.value;
    setContractType(value);
    if (value !== "Other") {
      setOtherValue("");
    }
  };

  const [errors, setErrors] = useState({
    jobTitle: "",
    hiringManagerId: "",
    workingLocation: "",
    workLocationRatio: "",
    vacancyReason: "",
    contractType: "",
    hoursPerWeek: "",
    salaryRange: "",
    benefitsWithThisRole: "",
  });

  // user Data
  const getUserData = async () => {
    const auth = getToken()
    const response = await getRequest(getUserProfile(auth?.uId))
    const { city, state, country, zipCode, secondaryBenefits } = response?.data?.data?.companyInfo
    setBenefitsWithThisRole(secondaryBenefits)
    setWorkingLocation(`${city},${state},${country},${zipCode}`)
  }

  useEffect(() => {
    dispatch(fetchHiringManagers());
    if (!mode) getUserData()
    if (initialData) {
      setJobTitle(initialData?.jobTitle);
      setHiringManagerId(initialData?.hiringManagerId);
      setWorkingLocation(initialData?.workingLocation);
      setWorkLocationRatio(initialData?.workLocationRatio);
      setVacancyReason(initialData?.vacancyReason);
      setContractType(initialData?.contractType);
      setHoursPerWeek(initialData?.hoursPerWeek);
      setSalaryRange(initialData?.salaryRange);
      setBenefitsWithThisRole(initialData?.benefitsWithThisRole);
    }

  }, []);

  const hiringManager = useSelector((state: RootState) => state.hiringManager);
  useEffect(() => {
    if (hiringManager.length > 0 && initialData.hiringManagerId) {
      const selectedManager = hiringManager.find(
        (hiring) =>
          hiring.id.toString() === initialData.hiringManagerId.toString()
      );
      if (selectedManager) {
        setHiringManagerName(selectedManager.name);
      }
    }
  }, [hiringManager]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let contractTypes = contractType;
    if (contractTypes === "Other") {
      contractTypes = otherValue;
    }
    const data = {
      jobTitle,
      hiringManagerId,
      workingLocation,
      workLocationRatio,
      vacancyReason,
      contractType: contractTypes,
      hoursPerWeek,
      salaryRange,
      benefitsWithThisRole,
    };
    if (formIsValid()) {
      if (mode) {
        setLoading(true)
        const response = await putRequest(updateJob(params?.id), data);
        setFormData(response?.data?.data);
        setLoading(false)
        onNext();
      } else {
        const allData = {
          ...initialData,
          ...data,
        };
        setFormData(allData);
        onNext(); // Call the onNext function to navigate to the next component
      }
    }
  };

  // For Timing
  const [titleTimer, setTitleTimer] = useState<NodeJS.Timeout | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [foundJobData, setFoundJobData] = useState<any>(null);

  const handleChangesTitle = (e: any) => {
    e.preventDefault();
    const enteredJobTitle = e.target.value;
    setJobTitle(enteredJobTitle);
    if (!mode) {
      if (titleTimer) {
        clearTimeout(titleTimer);
      }

      setTitleTimer(
        setTimeout(async () => {
          try {
            const response = await postRequest(getJobByName, {
              name: enteredJobTitle,
            });
            if (response?.status === 200 && response?.data) {
              setFoundJobData(response.data.data);
              setOpenDialog(true);
            } else {
              setAiLoading(true);
              const object = {
                vacancyReason: [
                  `Title: ${jobTitle} Question: What is the reason why this position is vacant?`,
                ],
                technicalSkillsRequired: [
                  `Title : ${jobTitle} Question : Are there any specific technical skills required for the role?`,
                ],
                softSkillsRequired: [
                  `Title : ${jobTitle} Question : What soft skills are you looking for in a candidate?`,
                ],
                idealCandidateProfile: [
                  `Title : ${jobTitle} Question :Do you have an ideal candidate in mind when you think about this vacancy (what we can use as a reference)?`,
                ],
                roleChallenges: [
                  `Title : ${jobTitle} Question : What challenges might the candidate face in this role?`,
                ],
                organizationalGrowthOpportunities: [
                  `Title : ${jobTitle} Question : What growth opportunities are available within the organization?`,
                ],
                successMetrics: [
                  `Title : ${jobTitle} Question : How will success be measured in this role?`,
                ],
                teamInteraction: [
                  `Title : ${jobTitle} Question : How does this role interact with other teams or departments within the organization?`,
                ],
                collaborationRequirements: [
                  `Title : ${jobTitle} Question : Are there specific collaboration requirements?`,
                ],
                cultureFit: [
                  `Title : ${jobTitle} Question : What is the team culture like, and what attributes should the candidate possess to fit in well?`,
                ],
              };
              const data = (await generateTextObject(
                object
              )) as GeneratedTextResponse;
              setVacancyReason(data?.vacancyReason);
              initialData.technicalSkillsRequired =
                data?.technicalSkillsRequired;
              initialData.softSkillsRequired = data?.softSkillsRequired;
              initialData.idealCandidateProfile = data?.idealCandidateProfile;
              initialData.roleChallenges = data?.roleChallenges;
              initialData.organizationalGrowthOpportunities =
                data?.organizationalGrowthOpportunities;
              initialData.successMetrics = data?.successMetrics;
              initialData.teamInteraction = data?.teamInteraction;
              initialData.collaborationRequirements =
                data?.collaborationRequirements;
              initialData.cultureFit = data?.cultureFit;
              setAiLoading(false);
            }
          } catch (error) {
            console.error("Error in handleChangesTitle:", error);
          }
        }, 3000)
      );
    }
  };

  const handleDialogConfirm = () => {
    if (foundJobData) {
      setBenefitsWithThisRole(foundJobData?.benefitsWithThisRole);
      setSalaryRange(foundJobData?.salaryRange);
      setHoursPerWeek(foundJobData?.hoursPerWeek);
      setContractType(foundJobData?.contractType);
      setVacancyReason(foundJobData?.vacancyReason);
      setWorkingLocation(foundJobData?.workingLocation);
      setWorkLocationRatio(foundJobData?.workLocationRatio);
      initialData.taskOfThisPosition = foundJobData?.taskOfThisPosition;
      initialData.anyProjectsOfCandidates =
        foundJobData?.anyProjectsOfCandidates;
      initialData.educationAndExperience = foundJobData?.educationAndExperience;
      initialData.technicalSkillsRequired =
        foundJobData?.technicalSkillsRequired;
      initialData.softSkillsRequired = foundJobData?.softSkillsRequired;
      initialData.preferredCertificationsLicenses =
        foundJobData?.preferredCertificationsLicenses;
      initialData.idealCandidateProfile = foundJobData?.idealCandidateProfile;
      initialData.idealExperienceLevel = foundJobData?.idealExperienceLevel;
      initialData.industrySpecificExperienceRequired =
        foundJobData?.industrySpecificExperienceRequired;
      initialData.roleChallenges = foundJobData?.roleChallenges;
      initialData.organizationalGrowthOpportunities =
        foundJobData?.organizationalGrowthOpportunities;
      initialData.positionGoals = foundJobData?.positionGoals;
      initialData.successMetrics = foundJobData?.successMetrics;
      initialData.teamInteraction = foundJobData?.teamInteraction;
      initialData.collaborationRequirements =
        foundJobData?.collaborationRequirements;
      initialData.reportingStructure = foundJobData?.reportingStructure;
      initialData.cultureFit = foundJobData?.cultureFit;
      initialData.positionTimeline = foundJobData?.positionTimeline;
      initialData.urgentNeeds = foundJobData?.urgentNeeds;
      initialData.interviewProcess = foundJobData?.interviewProcess;
      initialData.additionalRequirements = foundJobData?.additionalRequirements;
    }
    setOpenDialog(false);
  };

  const handleDialogCancel = async () => {
    setOpenDialog(false);
    if (!mode) {
      try {
        setAiLoading(true);
        const object = {
          vacancyReason: [
            `Title: ${jobTitle} Question: What is the reason why this position is vacant?`,
          ],
          technicalSkillsRequired: [
            `Title : ${jobTitle} Question : Are there any specific technical skills required for the role?`,
          ],
          softSkillsRequired: [
            `Title : ${jobTitle} Question : What soft skills are you looking for in a candidate?`,
          ],
          idealCandidateProfile: [
            `Title : ${jobTitle} Question :Do you have an ideal candidate in mind when you think about this vacancy (what we can use as a reference)?`,
          ],
          roleChallenges: [
            `Title : ${jobTitle} Question : What challenges might the candidate face in this role?`,
          ],
          organizationalGrowthOpportunities: [
            `Title : ${jobTitle} Question : What growth opportunities are available within the organization?`,
          ],
          successMetrics: [
            `Title : ${jobTitle} Question : How will success be measured in this role?`,
          ],
          teamInteraction: [
            `Title : ${jobTitle} Question : How does this role interact with other teams or departments within the organization?`,
          ],
          collaborationRequirements: [
            `Title : ${jobTitle} Question : Are there specific collaboration requirements?`,
          ],
          cultureFit: [
            `Title : ${jobTitle} Question : What is the team culture like, and what attributes should the candidate possess to fit in well?`,
          ],
        };
        const data = (await generateTextObject(
          object
        )) as GeneratedTextResponse;
        setVacancyReason(data?.vacancyReason);
        initialData.technicalSkillsRequired = data?.technicalSkillsRequired;
        initialData.softSkillsRequired = data?.softSkillsRequired;
        initialData.idealCandidateProfile = data?.idealCandidateProfile;
        initialData.roleChallenges = data?.roleChallenges;
        initialData.organizationalGrowthOpportunities =
          data?.organizationalGrowthOpportunities;
        initialData.successMetrics = data?.successMetrics;
        initialData.teamInteraction = data?.teamInteraction;
        initialData.collaborationRequirements = data?.collaborationRequirements;
        initialData.cultureFit = data?.cultureFit;
        setAiLoading(false);
      } catch (error) {
        console.error("Error in generating AI data:", error);
      }
    }
  };

  const formIsValid = () => {
    const newErrors = {
      jobTitle: !jobTitle?.trim() ? "Job Title is required" : "",
      hiringManagerId: !hiringManagerId ? "Hiring Manager is required" : "",
      workingLocation: !workingLocation?.trim()
        ? "Working Location is required"
        : "",
      workLocationRatio: !workLocationRatio?.trim()
        ? "Working Location Ratio is required"
        : "",
      vacancyReason: !vacancyReason?.trim() ? "Vacancy Reason is required" : "",
      contractType: !contractType?.trim() ? "Contract Type is required" : "",
      hoursPerWeek: !hoursPerWeek?.trim() ? "Hours is required" : "",
      salaryRange: !salaryRange?.trim() ? "Salary Range is required" : "",
      benefitsWithThisRole: !benefitsWithThisRole?.trim()
        ? "Benefits is required"
        : "",
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === "");
  };

  // DropDown
  const [hiringManagerName, setHiringManagerName] = useState("");
  const handleSelectChange = (e: any) => {
    const selectedManagerId = e.target.value;
    const selectedManager = hiringManager.find(
      (manager) => manager.id === selectedManagerId
    );
    if (selectedManager) {
      setHiringManagerId(selectedManager.id.toString());
      setHiringManagerName(selectedManager?.name);
    }
  };


  return (
    <div>
      <Container
        style={{
          display: "flex",
          padding: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <div className="col-12 pb-1">
              <ArrowBackIcon onClick={goBack} style={{ cursor: 'pointer' }} />
            </div>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item width="fit-content">
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Overview & Job features
                </Typography>
                <Grid mb={1}></Grid>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid my={4}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Add hiring manager to the job{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={hiringManagerId}
                fullWidth
                displayEmpty
                onChange={(e) => handleSelectChange(e)}
                renderValue={(value) => (
                  <span className={value === "" ? "placeholder" : ""}>
                    {value === "" ? "Select" : hiringManagerName}
                  </span>
                )}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {hiringManager.map((manager) => (
                  <MenuItem key={manager.id} value={manager.id}>
                    {manager.name}
                  </MenuItem>
                ))}
              </Select>
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.hiringManagerId}
              </InputLabel>
            </Grid>
            <Grid my={2}></Grid>
            <Grid mt="25px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            //   className="input-label-custom"
            >
              Overview
            </Typography>
            <Grid mt="25px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What is the specific job title for this role?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What is the specific job title for this role?"
                fullWidth
                className="input-box-custom"
                error={!!errors.jobTitle}
                helperText={errors.jobTitle}
                value={jobTitle}
                onChange={(e) => handleChangesTitle(e)}
              // onChange={(e) => setJobTitle(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What is the working location?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What is the working location?"
                fullWidth
                className="input-box-custom"
                error={!!errors.workingLocation}
                helperText={errors.workingLocation}
                value={workingLocation}
                onChange={(e) => setWorkingLocation(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What is the working on location / working remote ratio?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What is the working on location / working remote ratio?"
                fullWidth
                className="input-box-custom"
                error={!!errors.workLocationRatio}
                helperText={errors.workLocationRatio}
                value={workLocationRatio}
                onChange={(e) => setWorkLocationRatio(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography
                //  color="#4E4E4E"
                //  fontWeight={500}
                //  fontSize="14px"
                className="input-label-custom"
              >
                What is the reason why this position is vacant?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>

              {/* <TextareaAutosize
                placeholder="What is the reason why this position is vacant? "
                minRows={4}
                className="text-area"
              /> */}
              <OutlinedInput
                placeholder="What is the reason why this position is vacant?"
                multiline
                minRows={4}
                style={{ display: "inline-block", padding: "25px 11px" }}
                className="text-area-size"
                // endAdornment={
                //   <InputAdornment position="start">
                //     {/* <Button variant="contained" >
                //       Your Button
                //     </Button> */}
                //     <Grid
                //       item
                //       bgcolor="#84E1CC"
                //       padding="9px 22px 9px 16px"
                //       width="fit-Content"
                //       borderRadius="4px"
                //     >
                //       <Grid
                //         direction="row"
                //         display="flex"
                //         alignItems="center"
                //         gap="7px"
                //       >
                //         {loading ? (
                //           <CircularProgress size={20} color="inherit" />
                //         ) : (
                //           <>
                //             <img src={AiIcon} alt="ai-icon" />
                //             <Typography
                //               fontWeight={500}
                //               fontSize="12.55px"
                //               color="#FFFFFF"
                //               onClick={handleAISugeestion}
                //               style={{ cursor: "pointer" }}
                //             >
                //               Ai Suggestion here
                //             </Typography>
                //           </>
                //         )}
                //       </Grid>
                //     </Grid>
                //   </InputAdornment>
                // }
                value={vacancyReason}
                onChange={(e) => setVacancyReason(e.target.value)}
              />
              <InputLabel
                style={{
                  color: "#f44336",
                  paddingTop: "3px",
                  fontSize: "12px",
                  paddingLeft: "10px",
                }}
              >
                {errors.vacancyReason}
              </InputLabel>
            </Grid>
            <Grid mt="25px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            //   className="input-label-custom"
            >
              Contract type
            </Typography>
            <Grid mt="25px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What type of contract for this position?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <Select
                labelId="contract-type-select-label"
                id="contract-type-select"
                value={contractType}
                fullWidth
                displayEmpty
                onChange={handleContractTypeChange}
                renderValue={(value) => (
                  <span className={value === "" ? "placeholder" : ""}>
                    {value === "" ? "Select contract type" : value}
                  </span>
                )}
              >
                <MenuItem value="" disabled>
                  Select Contract type
                </MenuItem>
                {contractTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              {contractType === "Other" && (
                <TextField
                  fullWidth
                  sx={{ mt: 2 }}
                  value={otherValue}
                  onChange={(event) => {
                    setOtherValue(event.target.value);
                  }}
                  placeholder="Please specify"
                />
              )}
            </Grid>
            <InputLabel
              style={{
                color: "#f44336",
                paddingTop: "4px",
                fontSize: "12px",
                paddingLeft: "10px",
              }}
            >
              {errors.contractType}
            </InputLabel>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                How many hours per week? <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="How many hours per week?"
                fullWidth
                className="input-box-custom"
                error={!!errors.hoursPerWeek}
                helperText={errors.hoursPerWeek}
                value={hoursPerWeek}
                onChange={(e) => setHoursPerWeek(e.target.value)}
              />
            </Grid>
            <Grid mt="25px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            //   className="input-label-custom"
            >
              Salary and Benefits
            </Typography>
            <Grid mt="25px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What is the budgeted salary range for this position?{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="What is the budgeted salary range for this position?"
                fullWidth
                className="input-box-custom"
                error={!!errors.salaryRange}
                helperText={errors.salaryRange}
                value={salaryRange}
                onChange={(e) => setSalaryRange(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Are there any additional benefits or perks associated with the
                role? <span style={{ color: "red" }}>*</span>
              </Typography>
              <Grid mb={1}></Grid>
              <TextField
                sx={{
                  // mt: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 11px", // Add padding here
                  },
                }}
                placeholder="Are there any additional benefits or perks associated with the role?"
                fullWidth
                className="input-box-custom"
                error={!!errors.benefitsWithThisRole}
                helperText={errors.benefitsWithThisRole}
                value={benefitsWithThisRole}
                onChange={(e) => setBenefitsWithThisRole(e.target.value)}
              />
            </Grid>
            <Grid my={2}></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2}></Grid>
              <Grid item xs={mode ? 3 : 2} style={{ maxWidth: "fitContent" }}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                    border:'none',
                    color:'white',
                    width:' 100%'
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? (
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save & Next"
                    )
                  ) : (
                    "Continue"
                  )}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <LoadingOverlay loading={aiLoading} />
      <ConfirmationDialog
        open={openDialog}
        onConfirm={handleDialogConfirm}
        onCancel={handleDialogCancel}
        title="Confirmation"
        message="A similar job title already exists. Do you want to use the details of the existing job?"
      />
    </div>
  );
};

export default JobProfileFirst;
