import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Container,
  useMediaQuery,
  Box,
  Button,
  TextareaAutosize,
  MenuItem,
  TextField,
  InputLabel,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "../../styles/inputBox.css";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import parkSolidIcon from "../../assets/images/CandidateIcon/park-solid-color.svg";
import { useParams } from "react-router-dom";
import { putRequest } from "../../api/apiServices";
import { updateCandidate } from "../../api/apiList";

interface CandidateSpeFourthProps {
  onNext: () => void; // Function to navigate to the next component
  onBack: () => void;
  setCandidateData: (data: any) => void;
  initialData: any;
  mode: any;

}

const CandidateSpeFourth: React.FC<CandidateSpeFourthProps> = ({ onNext, onBack, setCandidateData, initialData, mode }: CandidateSpeFourthProps) => {
  const isMobile = useMediaQuery("(max-width:578px)");

  // To Handle Field
  const [responsibilitiesChallenges, setResponsibilitiesChallenges] = useState("")
  const [ambitionsGrowthPath, setAmbitionsGrowthPath] = useState("")
  const [projects, setProjects] = useState("")
  const [newOrganization, setNewOrganization] = useState("")
  const [companyMission, setCompanyMission] = useState("")
  const [loading,setLoading] = useState(false)

  //params 
  const params = useParams()

  const [errors, setErrors] = useState({
    responsibilitiesChallenges: "",
    ambitionsGrowthPath: "",
    projects: "",
    newOrganization: "",
    companyMission: "",
  });

  const formIsValid = () => {
    const newErrors = {
      responsibilitiesChallenges: !responsibilitiesChallenges?.trim() ? "Responsibilities and Challenges is required" : "",
      ambitionsGrowthPath: !ambitionsGrowthPath?.trim() ? "Ambitions And Ideal Growth Path is required" : "",
      projects: !projects?.trim() ? "Projects Or Tasks Bring is required" : "",
      newOrganization: !newOrganization?.trim() ? "factors are most important to you when considering a new organization is required" : "",
      companyMission: !companyMission?.trim() ? "Values And Mission is required" : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  useEffect(() => {
    setResponsibilitiesChallenges(initialData?.responsibilitiesChallenges)
    setAmbitionsGrowthPath(initialData?.ambitionsGrowthPath)
    setProjects(initialData?.projects)
    setNewOrganization(initialData?.newOrganization)
    setCompanyMission(initialData?.companyMission)
  }, [])


  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      responsibilitiesChallenges,
      ambitionsGrowthPath,
      projects,
      newOrganization,
      companyMission
    }
    if (formIsValid()) {
      if (mode) {
        setLoading(true)
        const response = await putRequest(updateCandidate(params?.id), data)
        setCandidateData(response?.data?.data)
        setLoading(false)
        onNext()
      } else {
        const allData = {
          ...initialData,
          ...data,
        };
        setCandidateData(allData)
        onNext();
      }
    }
  };
  const handleBack = (event: any) => {
    event.preventDefault();
    // Handle form submission logic here
    onBack(); // Call the onNext function to navigate to the next component
  };

  return (
    <div>
      <Container
        style={{
          display: "flex",
          padding: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          spacing={2}
          bgcolor="#FFFFFF"
          width="884px"
          borderRadius="22px"
          border="1px solid #D6D6D7"
          margin={0}
        >
          <Grid
            item
            height={isMobile ? "auto" : "auto"}
            style={{ padding: 30 }}
          >
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={isMobile ? "10px" : ""}
            >
              <Grid item width="fit-content">
                <Typography color="#273848" fontWeight={700} fontSize="20.94px">
                  Skills & Traits
                </Typography>
              </Grid>
              <Grid item gap={2} display="flex">
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#84E1CC",
                  }}
                />
                <Box
                  sx={{
                    width: 36,
                    height: 8,
                    borderRadius: 4,
                    bgcolor: "#DFF4EF",
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt="17px"></Grid>
            <Grid
              display="flex"
              alignItems="center"
              border="1px solid #c3c3c3"
              borderRadius="8px"
              padding="8px 0px 6px 15px"
            >
              <Grid direction="row" display="flex" gap={1} alignItems="center">
                <img src={parkSolidIcon} alt="overview-icon" />
                <Typography
                  //   className="input-label-custom"
                  fontWeight={700}
                  fontSize="13.94px"
                  lineHeight="13.94px"
                  color="#273848"
                >
                  Ideal situation & Other interviews
                </Typography>
              </Grid>
            </Grid>
            <Grid mt="17px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                Can you describe your ideal job in terms of responsibilities and
                challenges?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={responsibilitiesChallenges}
                onChange={(e) => setResponsibilitiesChallenges(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.responsibilitiesChallenges}</InputLabel>
            <Grid mt="20px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What are you ambitions regarding your career? What is your ideal
                growth path?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={ambitionsGrowthPath}
                onChange={(e) => setAmbitionsGrowthPath(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.ambitionsGrowthPath}</InputLabel>
            <Grid mt="20px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What kind of projects or tasks bring out the best in you
                professionally?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={projects}
                onChange={(e) => setProjects(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.projects}</InputLabel>
            <Grid mt="34px"></Grid>
            <Typography
              color="#273848"
              fontWeight={700}
              lineHeight="14.94px"
              fontSize="15px"
            >
              Ideal Organization
            </Typography>
            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                What factors are most important to you when considering a new
                organization?
              </Typography>
              <Grid mb="5px"></Grid>
              <TextField
              sx={{
                // mt: 1,
                "& .MuiOutlinedInput-input": {
                  padding: "10px 11px", // Add padding here
                },
              }}
                placeholder="What factors are most important to you when considering a new organization?"
                fullWidth
                className="input-box-custom"
                error={!!errors.newOrganization}
                helperText={errors.newOrganization}
                value={newOrganization}
                onChange={(e) => setNewOrganization(e.target.value)}
              />
            </Grid>
            <Grid mt="22px"></Grid>
            <Grid item>
              <Typography className="input-label-custom">
                How do you align with our company's values and mission?
              </Typography>
              <Grid mb="5px"></Grid>
              <OutlinedInput
                placeholder="Type here..."
                multiline
                minRows={4}
                className="text-area-size"
                value={companyMission}
                onChange={(e) => setCompanyMission(e.target.value)}
              />
            </Grid>
            <InputLabel style={{ color: '#f44336', paddingTop: "3px", fontSize: "12px", paddingLeft: "10px" }}>{errors.companyMission}</InputLabel>
            <Grid mt="37px"></Grid>
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid xs={2}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #000000",
                    color: "#273848",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    textTransform: "none",
                    letterSpacing: "0px",
                    width:'100%'
                    // padding: "10px 50px",
                  }}
                  onClick={(event) => handleBack(event)}
                >
                  Back
                </button>
              </Grid>
              <Grid item xs={mode ? 3 :2} style={{ maxWidth: "fitContent" }}>
                <button
                  type="submit"
                  className="user-scale"
                  // variant="contained"
                  // fullWidth
                  style={{
                    backgroundColor: "#84E1CC",
                    boxShadow: "none",
                    borderRadius: "5px",
                    padding: "17px 17px",
                    lineHeight: "0px",
                    letterSpacing: "0px",
                    textTransform: "none",
                    color:'white',
                    width:'100%',
                    border:'none'
                  }}
                  onClick={(event) => handleSubmit(event)}
                >
                  {mode ? loading ?<CircularProgress size={20}  color="inherit"/>   : "Save & Next" : "Continue"}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CandidateSpeFourth;
