import React, { useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import '../styles/Header/header.css';
import User from '../assets/images/User.svg';
import { clearSessionStorage, getToken } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import { getRequest } from '../api/apiServices';
import { getUserProfile } from '../api/apiList';
import Loader from '../components/Loader';
import { MutableRefObject } from 'react';
import Toast from '../utils/Toast';


const drawerWidth = 100;

interface HeaderProps {
  pageName?: string;
  isShow?: boolean; // Assuming userRole is a string
  className?: string;
  headerRef?: MutableRefObject<null>;
}

interface UserData {
  profile: string;
  name: string;
  status: string;
}

const Header: React.FC<HeaderProps> = ({ isShow, pageName, className, headerRef }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // explicitly define anchorEl type
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    // explicitly define event type
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleEditUserProfile = () => {
    const auth = getToken();
    navigate(`/update-user-profile/${auth?.uId}`);
  };

  const handleEditProfile = () => {
    const auth = getToken();
    navigate(`/update-company-profile/${auth?.uId}`);
  };
  const handleLogout = () => {
    clearSessionStorage();
    Toast('Logout Successfully.');
    navigate('/login');
  };
  const auth = getToken();

  const isMobile = useMediaQuery('(max-width:500px)');

  


  return isShow ? (
    <Box
      position="static"
      className={className}
      ref={headerRef}
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 1,
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Container className="header-main">
        <Typography className='text-shadow' variant="h4" component="div">
          {pageName}
        </Typography>
      </Container>

      <Container
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        className="header-main header-box"
      >
        <Grid
          direction="row"
          display="flex"
          alignItems="center"
          bgcolor="#FFFFFF"
          borderRadius="32px"
          border="1px solid #D6D6D7"
          padding="4px 4px 4px 19px"
          position="relative"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Grid>
            <Typography component="div">{auth?.name}</Typography>
            <Typography
              display="flex"
              justifyContent="flex-end"
              variant="body2"
            >
              {auth?.status == "0" ? "Recruiter" : "Hiring Manager"}
            </Typography>
          </Grid>
          <Grid mx="15px"></Grid>
          <Grid>
            <Box
              borderRadius="50%"
              display="flex"
              overflow="hidden"
              width="50px"
              height="50px"
            >
              <img
                src={auth?.profile || User}
                alt="User"
                style={{ width: "50px", height: "50px", objectFit: "cover" }}
              />
            </Box>
          </Grid>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                borderRadius: "10px",
                width: '150px',
                top: 0,
                position: 'fixed'
              },
            }}
          >
            <List>
              <ListItem button onClick={handleEditUserProfile} sx={{
                "&:hover": {
                  backgroundColor: "rgb(110, 196, 184,0.5)",
                },
              }}>
                <ListItemText primary="Edit Profile" />
              </ListItem>
              {auth?.status == "0" ? (
                <ListItem button onClick={handleEditProfile} sx={{
                  "&:hover": {
                    backgroundColor: "rgb(110, 196, 184,0.5)",
                  },
                }}>
                  <ListItemText primary="Edit Compnay Profile" />
                </ListItem>
              ) : (
                ""
              )}

              <ListItem button onClick={handleLogout} sx={{
                "&:hover": {
                  backgroundColor: "rgb(110, 196, 184,0.5)",
                },
              }}>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Popover>
        </Grid>
      </Container>
    </Box>
  ) : (
    <span></span>
  );
};

export default Header;
