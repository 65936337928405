import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  IconButton,
  InputBase,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import plusIcon from "../../assets/images/plus.svg";
import EditIcon from '../../assets/images/EditIcon.svg';
import DeleteIcon from '../../assets/images/deleteIcon.svg';
import "../../styles/dashboard.css";
import Header from "../../layout/Header";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";

const drawerWidth = 100;

interface Campaign {
  id: number;
  Campaign_name: string;
  ID: string;
  Publish_Date: string;
}

const CampaignListing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust breakpoint if necessary
  const navigate = useNavigate();

  const [campaign, setCampaign] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const tableRef = useRef(null);

  const handleSelect = (id: number) => {
    setSelectedCampaign(id);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter(event.target.value as string);
  };

  const handleClick = () => {
    navigate("/campaigns");
  };

  useEffect(() => {
    // Fetch or set your user data here
    const mockData: Campaign[] = [
      {
        id: 1,
        Campaign_name: "Campaign A",
        ID: "SP-012-XY-345",
        Publish_Date: "2023-05-22",
      },
      {
        id: 2,
        Campaign_name: "Campaign B",
        ID: "SP-045-ZX-678",
        Publish_Date: "2023-05-20",
      },
      {
        id: 3,
        Campaign_name: "Campaign C",
        ID: "SP-078-WY-901",
        Publish_Date: "2023-05-18",
      },
      // Add more mock data as needed
    ];


    setCampaign(mockData);
  }, []);

  const inputStyle = {
    backgroundColor: '#ffffff',
    padding: '6px 12px',
    borderRadius: '8px',
    boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
    height: '40px',
  };


  return (
    <>
      <Header  pageName="Campaign" isShow={true} />

      <Box
        sx={{
          // width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          mt: "50px",
          backgroundColor: "#f0f0f0",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Box  display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent={{ xs: 'flex-start', md: 'space-between' }} alignItems={{ xs: 'flex-start', md: 'center' }} mb={2}>
          <Container>
            <button
              type="submit"
              className="user-scale"
              // variant="contained"
              style={{
                backgroundColor: "#84E1CC",
                boxShadow: "none",
                borderRadius: "10px",
                fontSize: "16px",
                padding: "8px 27px",
                textTransform: "none",
                border: "1px solid #D6D6D7",
                height: '40px',
                color:'white',
                fontWeight:600
              }}
              onClick={handleClick}
            >
              Add New &nbsp;
              <img src={plusIcon} alt="Add new job" />
            </button>
          </Container>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }} mt={{ xs: 2, md: 0 }}>
            <Box
              className="user-box"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: isMobile ? 'auto' : '300px', // Set width dynamically based on screen size
                ...inputStyle,
                marginRight: '16px',
              }}
            >
              <SearchIcon />
              <InputBase
                placeholder="Search here..."
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ ml: 1, flex: 1, fontSize: '16px' }}
              />
            </Box>
            <FormControl className="user-box" sx={{ minWidth: 120, ...inputStyle, boxShadow: 'none', margin: { xs: '0', sm: '16px 0' }, }}>
              <InputLabel sx={{ color: 'inherit', marginTop:'-6px' }}>Filter</InputLabel>
              <Select
                value={filter}
                onChange={handleFilterChange}
                label="Filter"
                sx={{
                  height: '40px',
                  marginTop:'-4px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Remove the outline
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Remove the outline on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Remove the outline when focused
                  },
                  '& .MuiSelect-select': {
                    padding: '8px 12px', // Adjust padding if necessary
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="location">Location</MenuItem>
                <MenuItem value="hrname">Hiring Manager</MenuItem>
                <MenuItem value="created">Created Date</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Divider />
        <div ref={tableRef}>
        <Box mt={3} className="table-container">
          <Box className="table-wrapper" sx={{ backgroundColor: "#f0f0f0" }}>
            <Box display="flex" padding="16px 0" textAlign="center">
              <Box width="10%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Sr.
                </Typography>
              </Box>
              <Box width="30%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Campaign Name
                </Typography>
              </Box>
              <Box width="20%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  ID
                </Typography>
              </Box>
              <Box width="20%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Publish Date
                </Typography>
              </Box>
              <Box width="20%" display="flex" justifyContent="center">
                <Typography
                  fontSize="16px"
                  fontWeight={500}
                  color="#8C8C8C"
                  letterSpacing="normal"
                >
                  Edit/Delete
                </Typography>
              </Box>
            </Box>
            {campaign.map((jobItem, index) => (
              <Box
                className="row-shadow"
                display="flex"
                key={jobItem.id}
                alignItems="center"
                textAlign="center"
                bgcolor="#ffffff"
                borderRadius="4px"
                my={1}
                py={1}
              >
                <Box width="10%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#273848"
                  >
                    {index + 1}
                  </Typography>
                </Box>
                <Box width="30%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#273848"
                  >
                    {jobItem.Campaign_name}
                  </Typography>
                </Box>
                <Box width="20%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#273848"
                  >
                    {jobItem.ID}
                  </Typography>
                </Box>
                <Box width="20%" display="flex" justifyContent="center">
                  <Typography
                    fontSize="16px"
                    fontWeight={500}
                    color="#273848"
                  >
                    {jobItem.Publish_Date}
                  </Typography>
                </Box>
                <Box width="20%" display="flex" justifyContent="center">
                  <IconButton onClick={() => handleSelect(jobItem.id)}>
                    <img src={EditIcon} alt="Edit" style={{ width: '24px', height: '24px', color: "#273848" }} />
                  </IconButton>
                  <IconButton onClick={() => handleSelect(jobItem.id)}>
                    <img src={DeleteIcon} alt="Delete" style={{ width: '24px', height: '24px', color: "red" }} />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        </div>
      </Box>
    </>
  );
};

export default CampaignListing;
