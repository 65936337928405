// routes.tsx
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import SignIn from "../pages/SignIn/SignIn";
import SignUp from "../pages/SignUp/SignUp";
import EmailVerification from "../pages/EmailVerification/EmailVerification";
import AccountSuccess from "../pages/AccountCreated/AccountCretaed";
import Profile from "../pages/Profile/profile";
import JobSpecification from "../pages/JobSpecification/jobSpecification";
// import withAuth from "./auth/withAuth";
import { getToken } from "../services/authService";
import CondidateSpecification from "../pages/CandidateSpecification/CandidateSpe";
import Error from "../pages/Error/Error";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import MainLayout from "../layout/MainLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import JobListing from "../pages/JobSpecification/JobListing";
import PrivateComponent from "../utils/AuthComponent";
import ContentCreation from "../pages/ContentCreation/ContentCreation";
import CandidateListing from "../pages/CandidateSpecification/CandidateListing";
import CampaignListing from "../pages/Campaign/Campaign";
import UserProfile from "../components/Profile/UserProfile";
import UserPassword from "../components/Profile/UserPassword";
import Invitation from "../pages/Invitation/Invitation";
import JobCandiAnalysis from "../pages/JobCandiAnalysis/JobCandiAnalysis";
import GenerateJobOffer from "../pages/GenerateJobOffer/GenerateJobOffer";
import PostingPublishing from "../pages/PostingPublishing/PostingPublishing";
import PostPreview from "../pages/Post Preview/PostPreview";
import SubscriptionPlan from "../pages/SubScriptionPlan/Subscription";
import PaymentGateway from "../pages/PaymentGateway/PaymentGateway";
import PaymentSuccess from "../components/SuccessPayment/SuccessPayment";
import PaymentCancel from "../components/CancelPayment/CancelPayment";
import UpdateContentCreation from "../pages/ContentCreation/UpdateContentCreation";
import TermsCondition from '../components/Privacy-Policy/terms_conditions';
import PrivacyPolicy from '../components/Privacy-Policy/privacy_policy';
import Interviews from "../pages/Interviews/Interviews";
import InterviewProccess from "../pages/Interviews/InterviewProccess"
import ApplicationForm from "../pages/Form/ApplicationForm";
import CandidateLeadsDashboard from '../pages/CandidateLeadsDashboard/Dashboard';
import DashboardView from "../pages/CandidateLeadsDashboard/DashboardView";

const AppRoutes: React.FC = () => {
  const auth = getToken();

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/invitation/:id/:email" element={<Invitation />} />
        <Route path="/forgotpassowrd" element={<ForgotPassword />} />
        <Route path="/emailverify" element={<EmailVerification />} />
        <Route path="/accountsuccess" element={<AccountSuccess />} />
        <Route path="/terms-conditions" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/submit-application" element={<ApplicationForm />} />

        {/* Protected routes */}
        <Route element={<PrivateComponent />}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Dashboard />} />

            {/* Jobs */}
            <Route path="/jobs" element={<JobListing />} />
            <Route
              path="/jobs/create-jobprofile"
              element={<JobSpecification />}
            />
            <Route path="/jobs/update-job/:id" element={<JobSpecification />} />

            {/* Candidates */}
            <Route path="/candidates" element={<CandidateListing />} />
            <Route
              path="/candidates/create-candidate"
              element={<CondidateSpecification />}
            />
            <Route
              path="/candidates/update-candidate/:id"
              element={<CondidateSpecification />}
            />

            {/* campaigns */}
            <Route path="/campaigns" element={<CampaignListing />} />

            <Route path="/create-company-profile" element={<Profile />} />
            <Route path="/update-company-profile/:id" element={<Profile />} />
            
            <Route path="/update-user-profile/:id" element={<UserProfile />} />
            <Route
              path="/update-user-password/:id"
              element={<UserPassword />}
            />

            <Route path="/jobcan-analysis" element={<JobCandiAnalysis />} />
            <Route path="/generat-job-offer" element={<GenerateJobOffer />} />
            <Route path="/subscriptionplan" element={<SubscriptionPlan />} />
            <Route path="/payment-gateway/:code" element={<PaymentGateway />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-cancel" element={<PaymentCancel />} />

            <Route path="/content-creation" element={<ContentCreation />} />
            <Route
              path="/content-update/:id"
              element={<UpdateContentCreation />}
            />
            <Route path="/posting-publishing" element={<PostingPublishing />} />
            <Route path="/post-preview" element={<PostPreview />} />

            {/* Canndidate Leads Dashboard  */}
            <Route path='/candidate-leads-dashboard' element={<CandidateLeadsDashboard />} />
            <Route path='/candidate-leads-dashboard/view' element={<DashboardView />} />

            {/* INTERVIEWS  */}
            <Route path="/interview-planning" element={<Interviews />} />
            <Route path="/interview-proccess" element={<InterviewProccess />} />
          </Route>
        </Route>

        <Route
        path="/*"
          element={auth?.token ? <Error /> : <Error />}
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
