// const baseUrl = 'http://192.168.1.12:3000/api/';
// const baseUrl = "http://54.88.107.63:3000/api/";
const baseUrl = process.env.REACT_APP_BASE_URL;

module.exports = {
  //job
  createJob: `${baseUrl}create-job`,
  getJob: (id) => `${baseUrl}get-job/${id}`,
  updateJob: (id) => `${baseUrl}update-job/${id}`,
  jobsList: `${baseUrl}jobs`,
  deleteJob: (id) => `${baseUrl}delete-job/${id}`,
  getCompanyJob: `${baseUrl}company-jobs`,
  getJobByName: `${baseUrl}get-job`,

  //candidate
  createCandidate: `${baseUrl}create-candidate`,
  getCandidate: (id) => `${baseUrl}get-candidate/${id}`,
  updateCandidate: (id) => `${baseUrl}update-candidate/${id}`,
  candidatesList: `${baseUrl}candidates`,
  deleteCandidate: (id) => `${baseUrl}delete-candidate/${id}`,
  getCandidateByJob: `${baseUrl}get-candidate`,

  //company
  createCompany: `${baseUrl}create-company`,
  getCompany: (id) => `${baseUrl}get-company/${id}`,
  updateCompany: (id) => `${baseUrl}update-company/${id}`,

  //User
  getUserProfile: (id) => `${baseUrl}user/${id}`,
  updateUserProfile: (id) => `${baseUrl}update-user-profile/${id}`,
  updateUserPassword: (id) => `${baseUrl}update-user-password/${id}`,

  //Hirinn Manager
  getHiringManagerList: `${baseUrl}hiring-manager`,

  // Offer Letter
  genratePDFOfferLetter: `${baseUrl}offer-send-candidate`,
  sendOfferLetterEmail: `${baseUrl}send-email-candidate`,

  //Content Creations
  createContent: `${baseUrl}content/create`,
  getContentById: (id) => `${baseUrl}content/get/${id}`,
  contentList: `${baseUrl}content/list`,
  updateContent: (id) => `${baseUrl}content/update/${id}`,

  //Payment API
  initializePayment: `${baseUrl}payment/create-payment-intent`,
  confirmPayment: `${baseUrl}payment/confirm-payment`,

  // subscription list
  subscriptionList: `${baseUrl}subscription/list`,
  subscriptionGet: `${baseUrl}subscription/get`,
  activeSubscription: `${baseUrl}subscription/active`,
  deActiveSubscription: `${baseUrl}subscription/deactive`,
};
